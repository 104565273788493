import { useState } from 'react'
import classNames from 'classnames'
import { PanelContext, PanelProps } from 'common'

export function Panel(props: PanelProps) {
  const className = classNames('ibox', props.className)
  const collapsible = props.collapsible ?? false
  const [isCollapsed, setCollapsed] = useState<boolean>(props.collapsed ?? false)

  return (
    <PanelContext.Provider value={{ collapsible, isCollapsed, setCollapsed }}>
      <div className={className}>{props.children}</div>
    </PanelContext.Provider>
  )
}
