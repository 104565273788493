import classNames from 'classnames'
import { ModalFooterProps } from 'common'

export function ModalFooter(props: ModalFooterProps) {
  const className = classNames('modal-footer', props.className)

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  )
}
