/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { Button } from 'inspinia-template'

import './Grid.css'
import { GridRow } from './GridRow.tsx'

import { useNavigate } from 'react-router-dom'
import { FaClipboardCheck, FaFileExport, FaPencilAlt } from 'react-icons/fa'
import { httpGet } from 'common/services'
import { backendURL } from '../services/api'

export type GridProps = {
  actions?: number
  attributes: unknown[]
  baseUrl?: string
  identifier?: string
  values: unknown[]
  headers?: []
  items?: []
  sortable?: boolean
  striped?: boolean
  pageSize?: number
  toggle?: boolean
  toggleColumn?: string
  hiddenColumns?: unknown[]
}

export function Grid(props: GridProps) {
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(0)
  const [currentSortColumn, setCurrentSortColumn] = useState('')
  const [currentSortDirection, setCurrentSortDirection] = useState(1)

  const paginate = (instances: any, size: number): Array<any> => {
    let pageNumber = 0
    const pagedInstances: any[] = []

    instances.forEach((value: any, index: number) => {
      if (index >= (pageNumber + 1) * size) {
        pageNumber++
      }
      if (undefined === pagedInstances[pageNumber]) {
        pagedInstances[pageNumber] = []
      }
      pagedInstances[pageNumber].push(value)
    })

    return pagedInstances
  }

  function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName] // o[propertyName] is of type T[K]
  }

  const sort = (column: string) => {
    if (currentSortColumn === column) {
      setCurrentSortDirection(-1 * currentSortDirection)
    } else {
      setCurrentSortColumn(column)
      setCurrentSortDirection(1)
    }
    props.values.sort(
      (a, b) =>
        currentSortDirection *
        ('' + getProperty<any, string>(a, column)).localeCompare('' + getProperty<any, string>(b, column)),
    )
  }

  const pagedInstances = useMemo(() => paginate(props.values, props.pageSize || 15), [props.values, props.pageSize])

  interface RespostaValidacao {
    campo: string
    mensagem: string
    tipo: number
  }

  interface ListResponse<T> extends Response {
    parsedBody?: T[]
  }

  const validate = async (sku: number, target: string) => {
    // window.open(
    const response: ListResponse<RespostaValidacao> = (await httpGet<RespostaValidacao>(
      `${backendURL}/zuma/validate/${sku}/${target}`,
    )) as ListResponse<RespostaValidacao>
    const respostas = response.parsedBody

    let result = 0
    let mensagens = ''
    respostas?.forEach((element) => {
      if (element.tipo === 1) {
        result = 1
        mensagens = mensagens + ', ' + element.mensagem
      }
      if (element.tipo === 2) {
        result = 2
        mensagens = mensagens + ', ' + element.mensagem
      }
    })

    if (result === 0) {
      Swal.fire('Validar', 'Informações válidas para exportação para ' + target, 'success')
    } else if (result === 1) {
      Swal.fire(
        'Validar',
        'Informações válidas para exportação para ' + target + ' mas há alertas: ' + mensagens.substring(2),
        'warning',
      )
    } else {
      Swal.fire(
        'Validar',
        'Informações inválidas para exportação para ' + target + ': ' + mensagens.substring(2),
        'error',
      )
    }
  }
  const exportTo = async (sku: number, target: string) => {
    // window.open(
    const response: ListResponse<string> = (await httpGet<string>(
      `https://hiperstok.casetecnologia.com.br/backend/zuma/export/${sku}/${target}`,
    )) as ListResponse<string>
    const mensagem = response.parsedBody

    if (undefined === mensagem) {
      toast.success('SKU ' + sku + ' exportado com sucesso para ' + target, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: true,
        theme: 'colored',
        onClose: () => {},
      })
    } else {
      toast.error('SKU ' + sku + ' inválido para exportação para ' + target, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: true,
        theme: 'colored',
        onClose: () => {},
      })
    }
  }

  return (
    <table className={`table ${props.striped ? 'table-striped' : ''} table-bordered`}>
      <thead>
        <tr>
          {props.attributes.map((attribute: any, index) => (
            <th
              key={index}
              className={
                attribute.class +
                (props.hiddenColumns && props.hiddenColumns.includes(attribute.name) ? ' d-none' : '') +
                (attribute.name === currentSortColumn ? ' footable-sorted' : '') +
                (attribute.name === currentSortColumn && 1 === currentSortDirection ? '-desc' : '')
              }
              onClick={() => sort(attribute.name)}
            >
              {attribute.title}
              {props.sortable && <span className="footable-sort-indicator"></span>}
            </th>
          ))}
          <th className="text-right" data-sort-ignore="true">
            Ações
          </th>
        </tr>
      </thead>
      <tbody>
        {pagedInstances &&
          pagedInstances[currentPage] &&
          pagedInstances[currentPage].map((instance: any, index: number) => (
            <GridRow
              key={index}
              attributes={props.attributes}
              values={instance}
              toggle={props.toggle}
              toggleColumn={props.toggleColumn}
              hiddenColumns={props.hiddenColumns}
            >
              <Button
                size="mini"
                variant="secondary"
                onClick={() => navigate(`${props.baseUrl || '.'}/${instance[props.identifier || 'id']}`)}
              >
                <FaPencilAlt /> Editar
              </Button>

              {props.actions === 2 && (
                <Button
                  size="mini"
                  variant="secondary"
                  onClick={() => validate(instance[props.identifier || 'id'], 'anymarket')}
                >
                  <FaClipboardCheck /> Validar Anymarket
                </Button>
              )}
              {props.actions === 2 && (
                <Button
                  size="mini"
                  variant="secondary"
                  onClick={() => exportTo(instance[props.identifier || 'id'], 'anymarket')}
                >
                  <FaFileExport /> Exportar Anymarket
                </Button>
              )}
              {props.actions === 2 && (
                <Button
                  size="mini"
                  variant="secondary"
                  onClick={() => validate(instance[props.identifier || 'id'], 'tray')}
                >
                  <FaClipboardCheck /> Validar Tray
                </Button>
              )}
              {props.actions === 2 && (
                <Button
                  size="mini"
                  variant="secondary"
                  onClick={() => exportTo(instance[props.identifier || 'id'], 'tray')}
                >
                  <FaFileExport /> Exportar Tray
                </Button>
              )}
            </GridRow>
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={props.attributes.length + 1}>
            <ul className="pagination pagination-sm float-right">
              <li className="page-item" onClick={() => setCurrentPage(0)}>
                <a className="page-link" data-page="first" href="#first" onClick={(e) => e.preventDefault()}>
                  «
                </a>
              </li>
              <li
                className="page-item"
                onClick={() => {
                  if (currentPage > 0) setCurrentPage(currentPage - 1)
                }}
              >
                <a className="page-link" data-page="prev" href="#prev" onClick={(e) => e.preventDefault()}>
                  ‹
                </a>
              </li>
              {pagedInstances &&
                pagedInstances.map((value, index) => (
                  <li
                    key={index}
                    className={`page-item ${index === currentPage ? 'active' : ''}`}
                    onClick={() => setCurrentPage(index)}
                  >
                    <a className="page-link" data-page={index} href="#page" onClick={(e) => e.preventDefault()}>
                      {index + 1}
                    </a>
                  </li>
                ))}
              <li
                className="page-item"
                onClick={() => {
                  if (currentPage < pagedInstances.length - 1) setCurrentPage(currentPage + 1)
                }}
              >
                <a className="page-link" data-page="next" href="#next" onClick={(e) => e.preventDefault()}>
                  ›
                </a>
              </li>
              <li className="page-item" onClick={() => setCurrentPage(pagedInstances.length - 1)}>
                <a className="page-link" ata-page="last" href="#last" onClick={(e) => e.preventDefault()}>
                  »
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
