import { MenuItemProps } from 'common'

export function MenuItem(props: MenuItemProps) {
  return (
    <li role="menuitem">
      {!props.children && (
        <a className="dropdown-item" href={props.target} onClick={props.onClick}>
          {props.label}
        </a>
      )}
      {props.children}
    </li>
  )
}
