import classNames from 'classnames'
import React, { ComponentPropsWithoutRef, ReactNode, createContext, useState } from 'react'
import { SidebarNavigationGroup } from './SidebarNavigationGroup.tsx'

interface MenuContextType {
  activeItem: string
  setActiveItem: React.Dispatch<React.SetStateAction<string>>
}

export const MenuContext = createContext<MenuContextType | undefined>(undefined)

export type SidebarNavigationProps = ComponentPropsWithoutRef<'ul'> & {
  children?: ReactNode
  className?: string
  collapsedIcon: ReactNode
  expandedIcon: ReactNode
}

export function SidebarNavigation(props: SidebarNavigationProps) {
  const className = classNames('metismenu', 'nav', props.className)
  const [activeItem, setActiveItem] = useState<string>('')

  return (
    <MenuContext.Provider value={{ activeItem, setActiveItem }}>
      <ul className={className} id="side-menu">
        {props.children &&
          props.children.map((item) => {
            if (item.type === SidebarNavigationGroup) {
              return React.cloneElement(item, {
                collapsedIcon: props.collapsedIcon,
                expandedIcon: props.expandedIcon,
              })
            } else return item
          })}
      </ul>
    </MenuContext.Provider>
  )
}
