import { Col, Row } from '../design'
import { useListAll } from '../services/api'
import { Anymarketv2Category } from '../features/anymarket/types'

import { useState } from 'react'

export function Categorias() {
  const query = useListAll<Anymarketv2Category[]>('/anymarket/categories', '')

  if (!query.isFetched && !query.isFetching) {
    query.refetch()
  }
  // let categories: Anymarketv2Category[] = []

  // query.refetch().then((res) => {
  //   console.log(res)
  //   if (res.isSuccess && res.data) {
  //     categories = res.data
  //   }
  // })

  //   useEffect(() => {
  //     const produto = useRead('MultiversoProduto', props.id)
  //   }, [props.id])
  let flat: Anymarketv2Category[] = []

  const [filter, setFilter] = useState('')

  const filterChildren = (it: Anymarketv2Category, filter: string) => {
    let found = false
    console.log(it)
    if (it.name && it.name.match(new RegExp(filter, 'i'))) {
      found = true
    }
    if (it.children && it.children.length > 0) {
      it.children.map((ch) => {
        found = found || filterChildren(ch, filter)
      })
    }

    return found
  }

  const handleFilter = (e: React.ChangeEventHandler<HTMLInputElement>) => {
    const newFilter = e.target.value
    setFilter(newFilter)
    const filtered = flat.filter((instance) => {
      let found = false

      if (instance.name && instance.name.match(new RegExp(newFilter, 'i'))) {
        found = true
      }

      return found
    })

    console.log(flat)
  }

  return (
    <>
      <Row>
        <h3>Categorias</h3>
      </Row>
      <Row>
        <Col lg={6}>
          {/* <input type="text" onChange={handleFilter} /> */}
          <div className="dd">
            <ol className="dd-list">
              {query.data &&
                query.data.map((it) => {
                  flat = [...flat, it]
                  return renderItem(it as Anymarketv2Category, flat)
                })}
            </ol>
          </div>
        </Col>
      </Row>
      <br />
      <br />
    </>
  )
}

function renderItem(it: Anymarketv2Category, flat: Anymarketv2Category[]) {
  return (
    <>
      <li className="dd-item">
        <div className="dd-handle">{it.name}</div>
      </li>
      {it.children && it.children.length > 0 && (
        <ol className="dd-list">
          {it.children.map((ch) => {
            flat = [...flat, ch]
            return renderItem(ch, flat)
          })}
        </ol>
      )}
    </>
  )
}
