import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { httpDelete, httpGet, httpPatch, httpPost, httpPut } from 'common/services'

export const backendURL = import.meta.env.VITE_BACKEND_URL

export const formatBytes = (b: number) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let l = 0
  let n = b

  while (n >= 1024) {
    n /= 1024
    l += 1
  }

  if (n) {
    return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)} ${units[l]}`
  } else {
    return ''
  }
}

export const formatDuration = (seconds: number) => {
  const date = new Date(0)
  date.setSeconds(seconds)
  const dateString = date.toISOString().slice(11, 19)
  if (seconds < 3600) return dateString.slice(3)
  return dateString
}

export function useCall<T>(model: string, id: number | undefined, method: string, params?: unknown) {
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const postParams = {} as any
  postParams[model] = {}
  postParams[model][method] = params

  return useMutation({
    retry: false,
    onMutate: () => console.log('calling ' + (model ? model + (method ? '::' + method : '') : method)),
    mutationKey: [model, method],
    mutationFn: async () => {
      return httpPost<T>(
        `${backendURL}/${model}${id ? '/' + id : ''}`,
        model !== '' && method !== '' ? postParams : params,
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to post')
          }

          return res.value
        })
        .catch((reason) => {
          console.log(reason)
          if (reason === 401) {
            navigate('/login')
          }
        })
    },
  })
}

export function useCreate<T>(model: string, data: object) {
  const navigate = useNavigate()

  return useMutation({
    retry: false,
    onMutate: () => console.log('creating instance'),
    mutationKey: [model, 'create'],
    mutationFn: async () => {
      return httpPut<T>(`${backendURL}/${model}`, { data })
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to put')
          }

          return res.value
        })
        .catch((reason) => {
          console.log(reason)
          if (reason === 401) {
            navigate('/login')
          }
        })
    },
  })
}

export function useListAll<T>(model: string, filter?: string) {
  const navigate = useNavigate()

  // @todo passar o parametro filter

  return useQuery({
    enabled: false,
    retry: false,
    queryKey: [model, filter],
    queryFn: async ({ signal }) => {
      console.log('getting data: ' + model)

      return httpGet<T>(`${backendURL}/${model}`, { signal })
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch')
          }

          return res.value
        })
        .catch((reason) => {
          console.log(reason)
          if (reason === 401) {
            navigate('/login')
          }
        })
    },
  })
}

export function usePartialUpdate<T>(model: string, id: string | null, data: object) {
  const navigate = useNavigate()

  return useMutation({
    retry: false,
    onMutate: () => console.log('updating data'),
    mutationKey: [model, id],
    mutationFn: async () => {
      return httpPatch<T>(`${backendURL}/${model}${id ? '/' + id : ''}`, { data })
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to put')
          }

          return res.value
        })
        .catch((reason) => {
          console.log(reason)
          if (reason === 401) {
            navigate('/login')
          }
        })
    },
  })
}

export function useRead<T>(model: string, id: string) {
  const navigate = useNavigate()

  return useQuery({
    enabled: false,
    retry: false,
    queryKey: [model, id],
    queryFn: async ({ signal }) => {
      return httpGet<T>(`${backendURL}/${model}/${id}`, { signal })
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch')
          }

          return res.value
        })
        .catch((reason) => {
          console.log(reason)
          if (reason === 401) {
            navigate('/login')
          }
        })
    },
  })
}

export function useRemove<T>(model: string, id: string) {
  const navigate = useNavigate()

  return useMutation({
    retry: false,
    onMutate: () => console.log('deleting data'),
    mutationKey: [model, id],
    mutationFn: async () => {
      return httpDelete<T>(`${backendURL}/${model}/${id}`)
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to delete')
          }

          return res.value
        })
        .catch((reason) => {
          console.log(reason)
          if (reason === 401) {
            navigate('/login')
          }
        })
    },
  })
}

export function useUpdate<T>(model: string, id: string | null, data: object) {
  const navigate = useNavigate()

  return useMutation({
    retry: false,
    onMutate: () => console.log('updating data'),
    mutationKey: [model, id],
    mutationFn: async () => {
      return httpPut<T>(`${backendURL}/${model}${id ? '/' + id : ''}`, { data })
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to put')
          }

          return res.value
        })
        .catch((reason) => {
          console.log(reason)
          if (reason === 401) {
            navigate('/login')
          }
        })
    },
  })
}
