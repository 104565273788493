import React, { useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { ButtonProps, getShape, getVariant, getVariantText } from 'common'
import * as Ladda from 'ladda'

import './Button.scss'

export function Button(props: ButtonProps) {
  const {
    block = false,
    effect = 'zoom-in',
    icon,
    iconPosition = 'start',
    label,
    ladda = false,
    shadow = false,
    size,
    ...buttonProps
  } = props
  const buttonRef = useRef<HTMLButtonElement>(null)

  let laddaInstance: Ladda.LaddaButton

  let className = classNames(
    'btn',
    getShape(props.shape),
    getVariant('btn-', props.variant),
    getVariantText(props.variant),
    props.className,
  )
  className = className.replace('rounded-circle', 'btn-circle')
  className = className.replace('rounded-pill', 'btn-rounded')

  className +=
    (size === 'large' ? ' btn-lg' : '') + (size === 'small' ? ' btn-sm' : '') + (size === 'mini' ? ' btn-xs' : '')
  className += (block ? ' btn-block' : '') + (shadow ? ' dim' : '')

  useEffect(() => {
    if (ladda) {
      laddaInstance = Ladda.create(buttonRef.current as HTMLButtonElement)
    }

    return () => {
      if (ladda) {
        laddaInstance.remove()
      }
    }
  }, [])

  const laddaStart = useCallback(() => {
    laddaInstance.start()
  }, [])

  const laddaStop = useCallback(() => {
    laddaInstance.stop()
  }, [])

  const onClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (ladda) {
      laddaStart()
    }

    if (props.onClick) {
      await props.onClick(event)

      if (ladda) {
        laddaStop()
      }
    }
  }

  return (
    <button
      {...buttonProps}
      className={className}
      data-style={effect}
      ref={props.ref}
      onClick={ladda ? onClick : props.onClick}
    >
      <span className={ladda ? 'ladda-label' : ''}>
        {iconPosition === 'start' && icon}
        {label}
        {iconPosition === 'end' && icon}
      </span>
      {props.children}
    </button>
  )
}
