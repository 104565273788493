/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/ban-types */
import { useCallback, useEffect, useState } from 'react'

import { Button, Col, Row } from 'inspinia-template'
import { Grid } from './Grid'
import { FaSearch } from 'react-icons/fa'
import { useListAll } from '../services/api'

export type AttributeProps = { class: string; name: string; title: string; type?: string }

export type TelaListarProps = {
  attributes: AttributeProps[]
  breadcrumbs: string[]
  baseUrl: string
  model?: string
  title: string
}

export type AnymarketBrand = {
  id: number
  name: string
  reducedName: string
}

export function TelaListar<T>(props: TelaListarProps) {
  //const history = useHistory();

  const [searchFilter, setSearchFilter] = useState('')

  const { data, isFetched, isFetching, refetch } = useListAll<T[]>(props.baseUrl)

  if (!isFetched && !isFetching) {
    refetch()
  }

  const [filteredInstances, setFilteredInstances] = useState<any[]>([])

  const changeFilter = useCallback(
    (newFilter: string) => {
      setSearchFilter(newFilter)

      setFilteredInstances(
        data
          ? data.filter((instance) => {
              let found = false

              Object.entries(instance as Object).map(([key, value]) => {
                if (key !== 'revision' && key !== 'revisionAuthor' && key !== 'revisionDate') {
                  if (typeof value === 'number') {
                    if (value === Number(newFilter)) {
                      found = true
                    }
                  } else if (value && value.toString().match(new RegExp(newFilter, 'i'))) {
                    found = true
                  }
                }

                return <></>
              })

              return found
            })
          : [],
      )
    },
    [data],
  )

  useEffect(() => {
    changeFilter(searchFilter)
  }, [searchFilter, changeFilter])

  return (
    <div className="content-wrapper">
      <div className="content-heading">
        <div>Listar {props.title}</div>
        <div className="ml-auto">
          <div className="btn-group">
            {/* <Button icon="plus" label="Cadastrar novo" primary large onClick={() => history.push(`${props.baseUrl}/cadastrar`)} /> */}
          </div>
        </div>
      </div>
      <Row>
        <Col all={12}>
          <div className="ibox" style={{ overflowWrap: 'anywhere' }}>
            {/* <div className="ibox-title">
                            <h5>{props.title} cadastrados</h5>
                        </div> */}
            <div className="ibox-content">
              <div className="card">
                <div className="card-body">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Digite alguma coisa para pesquisar em qualquer campo..."
                      className="form-control"
                      value={searchFilter}
                      onChange={(event) => {
                        changeFilter(event.target.value)
                      }}
                    />
                    <div className="input-group-append">
                      <Button variant="secondary" icon={<FaSearch className="m-r-sm" />} iconPosition="start">
                        Pesquisar
                      </Button>
                    </div>
                  </div>
                  <div className="m-t-md">
                    {filteredInstances.length === 0 && <span className="m-t-md">Nenhum resultado encontrado</span>}
                    {filteredInstances.length === 1 && <span className="m-t-md">Um resultado encontrado</span>}
                    {filteredInstances.length > 1 && (
                      <span className="m-t-md">{filteredInstances.length} resultados encontrados</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="card b">
                <div className="card-body">
                  <Grid
                    actions={props.baseUrl === '/zuma/produtos' ? 2 : 0}
                    attributes={props.attributes}
                    baseUrl={props.baseUrl}
                    pageSize={50}
                    values={filteredInstances}
                    sortable
                    striped
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
