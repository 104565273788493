import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { httpPost } from 'common/services'
import { Button } from '../design'
import { Anymarketv2Product, Anymarketv2ProductCharacteristic } from '../features/anymarket/types'
import { backendURL, useRead } from '../services/api'
import { useLocalStorage } from 'common/hooks'

export function CaracteristicasProduto() {
  const { id, categoryId } = useParams<{ id: string; categoryId: string }>()

  const [storedData, setStoredData] = useLocalStorage('characteristics-' + id, null)

  const [inputFields, setInputFields] = useState<Anymarketv2ProductCharacteristic[]>([])

  const useProduto = useRead<Anymarketv2Product>('zuma/characteristics/' + id + '/', categoryId || '')

  // const [data, setData] = useState<Anymarketv2ProductCharacteristic[]>([])

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(inputFields)

    httpPost(backendURL + '/zuma/characteristics/' + id, inputFields).then((resp) => {
      if (resp.status == 200) {
        window.localStorage.removeItem('characteristics-' + id)

        toast.success('SKU ' + id + ': campo ' + 'Características' + ' alterado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          icon: true,
          theme: 'colored',
          onClose: () => {},
        })
      } else {
        toast.error('SKU ' + id + ' falhou ao aplicar alterações', {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          icon: true,
          theme: 'colored',
          onClose: () => {},
        })
      }
    })
  }

  const handleFormChange = (index, event) => {
    const data = [...inputFields]
    data[index][event.target.name] = event.target.value
    setInputFields(data)

    setStoredData(data)
  }

  const addFields = () => {
    const newfield = { index: 0, name: '', value: '' }

    setInputFields([...inputFields, newfield])
  }

  const removeFields = (index) => {
    const data = [...inputFields]
    data.splice(index, 1)
    setInputFields(data)
  }

  useEffect(() => {
    if (!useProduto.isFetched && !useProduto.isFetching) {
      useProduto.refetch().then((r) => {
        if (r.data && r.data.characteristics) {
          // setData(r.data.characteristics)
          setInputFields(r.data.characteristics?.sort((a, b) => a.name.localeCompare(b.name)))

          if (null != storedData) {
            if (
              confirm('Foram encontrados dados não salvos para o produto ' + id + '. Deseja recuperar esses dados?')
            ) {
              setInputFields([...inputFields, ...storedData])
            }
          }
        }
      })
    }
  })

  return (
    <>
      {useProduto.isFetched && useProduto.isSuccess && (
        <div className="m-b-md">
          <h2>
            {id} - {useProduto.data?.title}
          </h2>
        </div>
      )}

      {useProduto.isFetched &&
        useProduto.isSuccess &&
        inputFields.map((input, index) => {
          return (
            <div key={index}>
              <div className="m-t-sm">
                <input
                  type="text"
                  name="name"
                  value={input.name}
                  style={{ width: '240px' }}
                  onChange={(event) => handleFormChange(index, event)}
                />
                <input
                  type="text"
                  name="value"
                  value={input.value}
                  style={{ width: '360px' }}
                  onChange={(event) => handleFormChange(index, event)}
                />
                {index + 1 === inputFields.length && (
                  <Button
                    variant="primary"
                    size="mini"
                    className="m-l-xs"
                    style={{ paddingTop: 2, paddingBottom: 2, marginBottom: 3 }}
                    onClick={() => {
                      addFields()
                    }}
                  >
                    +
                  </Button>
                )}

                <Button
                  variant="secondary"
                  size="mini"
                  className="m-l-xs"
                  style={{ paddingTop: 2, paddingBottom: 2, marginBottom: 3, width: 21.2 }}
                  onClick={() => {
                    removeFields(index)
                  }}
                >
                  -
                </Button>
              </div>
            </div>
          )
        })}

      {useProduto.isFetched && useProduto.isSuccess && (
        <div className="m-t-md m-b-lg">
          <Button variant="primary" className="m-r-sm" onClick={handleSubmit}>
            Salvar
          </Button>
          <Button variant="secondary">Fechar</Button>
        </div>
      )}
    </>
  )
}
