import classNames from 'classnames'
import { ColProps } from 'common'

export function Col(props: ColProps) {
  const { all, xs, sm, md, lg, xl, xxl, ...divProps } = props

  const className = classNames(
    'col',
    all ? `col-${all}` : '',
    xs ? `col-xs-${xs}` : '',
    sm ? `col-sm-${sm}` : '',
    md ? `col-md-${md}` : '',
    lg ? `col-lg-${lg}` : '',
    xl ? `col-xl-${xl}` : '',
    xxl ? `col-xxl-${xxl}` : '',
    props.className,
  )

  return (
    <div {...divProps} className={className}>
      {props.children}
    </div>
  )
}
