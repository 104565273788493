import { createBrowserRouter, Outlet } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthProvider'
import { SettingsProvider } from './contexts/SettingsProvider'
import { Login } from './pages/Login.tsx'
import { MonitorarCobranca } from './features/blu/MonitorarCobranca.tsx'
import { ProtectedRoute } from './ProtectedRoute'
import { TelaListar } from './components/TelaListar.tsx'
import { TelaEditar } from './components/TelaEditar.tsx'
import { ErrorBoundary } from './ErrorBoundary'
import { Layout } from './layouts/Layout.tsx'
import {
  attributesAnymarketBrand,
  attributesAnymarketVariation,
  attributesMultiversoProduct,
  attributesMultiversoProductList,
} from './types'
import { Index } from './views/Index.tsx'
import { GridProdutos } from './views/GridProdutos'
// import { sentryCreateBrowserRouter } from './services/instrumentation.ts'
import { CancelarCobranca, ConsultarCliente, ConsultarCobranca, EnviarCobranca, Monitor } from './features/blu/'
import { Anymarketv2Brand } from './features/anymarket/types.ts'
import { Categorias } from './views/Categorias.tsx'
import { UploadImagens } from './views/UploadImagens.tsx'
import { CaracteristicasProduto } from './views/CaracteristicasProduto.tsx'
import { Estatisticas } from './features/szchat/Estatisticas.tsx'
import { SemEstoque } from './features/szchat/SemEstoque.tsx'

export const router = createBrowserRouter([
  //   { path: '/forgot-password', element: <RecuperarSenha /> },
  //   { path: '/reset-password', element: <RedefinirSenha /> },
  //   { path: '/register', element: <Registrar /> },
  //   { path: '/verify-mfa', element: <VerificarMFA /> },
  {
    path: '/',
    element: (
      <AuthProvider>
        <SettingsProvider>
          <Outlet />
        </SettingsProvider>
      </AuthProvider>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      { path: '/login', element: <Login /> },
      {
        path: '/',
        element: <ProtectedRoute element={<Layout element={<Outlet />} />} />,
        children: [
          {
            index: true,
            element: <Index />,
          },
          {
            path: 'anymarket',
            children: [
              {
                path: 'categorias',
                element: (
                  // <TelaListar<Anymarketv2Category[]>
                  //   breadcrumbs={['Anymarket', 'Categorias']}
                  //   title="Categorias"
                  //   model="AnymarketCategory"
                  //   baseUrl="/anymarket/categories"
                  //   attributes={attributesAnymarketCategory}
                  // />
                  <Categorias />
                ),
              },
              { path: 'cores', element: <Monitor /> },
              {
                path: 'brands',
                element: (
                  <TelaListar<Anymarketv2Brand>
                    breadcrumbs={['Anymarket', 'Marcas']}
                    title="Marcas"
                    baseUrl="/anymarket/brands"
                    attributes={attributesAnymarketBrand}
                  />
                ),
              },
              {
                path: 'brands/:id',
                element: (
                  <TelaEditar<Anymarketv2Brand>
                    breadcrumbs={['Anymarket', 'Marcas']}
                    title="Marcas"
                    model="AnymarketBrand"
                    baseUrl="/anymarket/brands"
                    attributes={attributesAnymarketBrand}
                  />
                ),
              },
              {
                path: 'pedidos',
                element: (
                  <TelaListar
                    breadcrumbs={['Anymarket', 'Pedidos']}
                    title="Pedidos"
                    model="AnymarketOrder"
                    baseUrl="/anymarket/orders"
                    attributes={attributesAnymarketBrand}
                  />
                ),
              },
              {
                path: 'produtos',
                element: (
                  <TelaListar
                    breadcrumbs={['Anymarket', 'Produtos']}
                    title="Produtos"
                    model="AnymarketProduct"
                    baseUrl="/anymarket/products"
                    attributes={attributesAnymarketBrand}
                  />
                ),
              },
              {
                path: 'transmissoes',
                element: (
                  <TelaListar
                    breadcrumbs={['Anymarket', 'Transmissões']}
                    title="Transmissões"
                    model="AnymarketTransmissions"
                    baseUrl="/anymarket/transmissions"
                    attributes={attributesAnymarketBrand}
                  />
                ),
              },
              {
                path: 'variacoes',
                element: (
                  <TelaListar
                    breadcrumbs={['Anymarket', 'Variações']}
                    title="Variações"
                    model="AnymarketVariations"
                    baseUrl="/anymarket/variations"
                    attributes={attributesAnymarketVariation}
                  />
                ),
              },
            ],
          },
          {
            path: 'blu',
            children: [
              { path: 'monitor', element: <Monitor /> },
              { path: 'cancelar-cobranca/:id', element: <CancelarCobranca /> },
              { path: 'consultar-cliente', element: <ConsultarCliente /> },
              { path: 'consultar-cobranca', element: <ConsultarCobranca /> },
              { path: 'consultar-cobranca/:id', element: <ConsultarCobranca /> },
              { path: 'enviar-cobranca', element: <EnviarCobranca /> },
              { path: 'enviar-cobranca/:cpfcnpj', element: <EnviarCobranca /> },
              { path: 'enviar-cobranca/:cpfcnpj/:prevenda', element: <EnviarCobranca /> },
              { path: 'monitorar-cobranca', element: <MonitorarCobranca /> },
              { path: 'monitorar-cobranca/:id', element: <MonitorarCobranca /> },
            ],
          },
          {
            path: 'szchat',
            children: [
              {
                path: 'statistics',
                element: <Estatisticas />,
              },
              {
                path: 'products-soldout',
                element: <SemEstoque />,
              },
            ],
          },
          {
            path: 'zuma',
            children: [
              {
                path: 'editar-produtos',
                element: <GridProdutos />,
              },
              {
                path: 'products',
                element: (
                  <TelaListar
                    breadcrumbs={['Zuma', 'Produtos']}
                    title="Produtos"
                    model="MultiversoProduct"
                    baseUrl="/zuma/products"
                    attributes={attributesMultiversoProductList}
                  />
                ),
              },
              {
                path: 'products/:id',
                element: (
                  <TelaEditar
                    breadcrumbs={['Zuma', 'Produtos']}
                    title="Produtos"
                    model="MultiversoProduct"
                    baseUrl="/zuma/products"
                    attributes={attributesMultiversoProduct}
                  />
                ),
              },
              {
                path: 'upload-imagens',
                element: <UploadImagens />,
              },
              {
                path: 'caracteristicas/:id',
                element: <CaracteristicasProduto />,
              },
              {
                path: 'caracteristicas/:id/:categoryId',
                element: <CaracteristicasProduto />,
              },
            ],
          },
        ],
      },
    ],
  },
])
