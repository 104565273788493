import { AG_GRID_LOCALE_PT } from '@ag-grid-community/locale'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Button, Col, Panel, PanelContent, Row } from 'inspinia-template'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FaCalendar } from 'react-icons/fa'
import { useListAll } from '../../services/api'
import { httpPost } from 'common/services'
import ReactExport from 'react-export-excel-ext'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export function SemEstoque() {
  const gridRef = useRef<AgGridReact>(null)

  const localeText = AG_GRID_LOCALE_PT

  const pagination = true
  const paginationPageSize = 25
  const paginationPageSizeSelector = [25, 50, 100, 200]
  //const products = useListAll('/szchat/soldout')
  const [dataInicial, setDataInicial] = useState('01/10/2024')
  const [dataFinal, setDataFinal] = useState('24/10/2024')

  const products = httpPost('/backend/szchat/soldout', { dataInicial: dataInicial, dataFinal: dataFinal })

  //   useEffect(() => {
  //     if (!products.isFetched && !products.isFetching) {
  //       products.refetch()
  //     }
  //   })

  const [rowData, setRowData] = useState([])

  useEffect(() => {
    products.then((data) => {
      setRowData(data.value)
    })
  }, [dataInicial, dataFinal])

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      editable: false,
      filter: true,
      floatingFilter: false,
    }
  }, [])

  const [colDefs] = useState<ColDef[]>([
    {
      field: 'sku',
      headerName: 'Sku',
    },
    { field: 'name', headerName: 'Nome' },
    { field: 'count', headerName: 'Quantidade de consultas' },
    { field: 'firstQuery', headerName: 'Primeira consulta' },
    { field: 'lastQuery', headerName: 'Última consulta' },
  ])
  return (
    <>
      <Panel className="m-t-md">
        <PanelContent>
          <Row>
            <Col sm={4}>
              <div className="form-group">
                <label className="col-form-label" htmlFor="date_start">
                  Data inicial
                </label>
                <div className="input-group date">
                  <span className="input-group-addon">
                    <FaCalendar />
                  </span>
                  <input
                    id="date_start"
                    type="text"
                    className="form-control"
                    defaultValue="01/10/2024"
                    onBlur={(e) => setDataInicial(e.target.value)}
                  />
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="form-group">
                <label className="col-form-label" htmlFor="date_end">
                  Data final
                </label>
                <div className="input-group date">
                  <span className="input-group-addon">
                    <FaCalendar />
                  </span>
                  <input
                    id="date_end"
                    type="text"
                    className="form-control"
                    defaultValue={new Date().toLocaleDateString()}
                    onBlur={(e) => setDataFinal(e.target.value)}
                  />
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="form-group">
                <label className="col-form-label" htmlFor="date_modified">
                  &#160;
                </label>
                <div className="input-group">
                  <Button label="Atualizar" variant="default" className="m-r-xs" />

                  <ExcelFile
                    filename={`Produtos consultados sem estoque entre ${dataInicial} e ${dataFinal}`}
                    element={<Button label="Exportar" variant="default" />}
                  >
                    <ExcelSheet data={rowData} name="Consultados sem estoque">
                      <ExcelColumn label="Sku" value="sku" />
                      <ExcelColumn label="Nome" value="name" />
                      <ExcelColumn label="Quantidade de consultas" value="count" />
                      <ExcelColumn label="Primeira consulta" value="firstQuery" />
                      <ExcelColumn label="Última consulta" value="lastQuery" />
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </div>
            </Col>
          </Row>
        </PanelContent>
      </Panel>

      <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{ height: '75%' }} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact
          autoSizeStrategy={{
            type: 'fitCellContents',
          }}
          className="m-t-sm"
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          localeText={localeText}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          ref={gridRef}
          rowData={rowData}
        />
      </div>
    </>
  )
}
