import { useState } from 'react'
import { TabSetProps } from 'common'
import { Tab, TabHeader } from '../main.ts'

export function TabSet(props: TabSetProps) {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className={`tabs-container${props.className ? ` ${props.className}` : ''}`}>
      <div className={props.headerPosition === 'top' ? '' : `tabs-${props.headerPosition}`}>
        <ul className="nav nav-tabs" role="tablist">
          {props.children.map((item, index) => {
            if (item.type === Tab)
              return (
                <TabHeader
                  active={index === activeTab}
                  badge={item.props.badge}
                  badgeClass={item.props.badgeClass}
                  badgePosition={item.props.badgePosition}
                  icon={item.props.icon}
                  iconPosition={item.props.iconPosition}
                  index={index}
                  key={index}
                  label={item.props.label}
                  setActiveTab={setActiveTab}
                />
              )
            return <></>
          })}
        </ul>
        <div className="tab-content">
          <div className="tab-pane active">{props.children[activeTab]}</div>
        </div>
      </div>
    </div>
  )
}
