import { FormEvent, useState } from 'react'
import { useAuth } from '../hooks/useAuth'
import { Button } from 'inspinia-template'

export const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const { login } = useAuth()

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (username !== '' && password !== '') {
      const result = await login(username, password)

      setError(!result)
    }
  }

  return (
    <div className="middle-box text-center loginscreen animated fadeInDown">
      <div>
        <form className="m-t" role="form" onSubmit={handleSubmit} style={{ marginTop: '20em' }}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Usuário"
              required={true}
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Senha"
              required={true}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <Button className="block full-width m-b" type="submit" variant="primary">
            Entrar
          </Button>
          {error && <div>Usuário ou senha incorretos</div>}
          <a href="#">
            <small>Esqueceu a senha?</small>
          </a>
        </form>
        <p className="m-t">
          <small>Case Tecnologia &copy; 2024</small>{' '}
        </p>
      </div>
    </div>
  )
}
