import { useQuery } from '@tanstack/react-query'
import { httpGet } from 'common/services'
import { Button, Col, Label, Panel, PanelContent, PanelHeader, Row, Text } from 'inspinia-template'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { backendURL } from '../../services/api'

type ConsultaClienteResponse = {
  data: string
  documento: string
  resultado: boolean
  usuario: number
}

export function ConsultarCliente() {
  let documento = ''
  const navigate = useNavigate()

  const [showAlert, setShowAlert] = useState(0)

  const { register, handleSubmit } = useForm<{ documento: string }>({ mode: 'onBlur' })

  const { error, data, isLoading, refetch } = useQuery({
    enabled: false,
    retry: false,
    queryKey: ['cliente', documento],
    queryFn: async ({ signal }) =>
      httpGet<ConsultaClienteResponse>(`${backendURL}/blu/client/${documento}`, { signal })
        .then((res) => {
          if (!res.ok) {
            setShowAlert(3)
            throw new Error('Failed to fetch')
          }

          return res.value
        })
        .catch((reason) => {
          console.log(reason)
          if (reason === 401) {
            navigate('/login')
          }
        }),
  })

  const consulta = async (d: { documento: string }) => {
    setShowAlert(0)
    documento = d.documento.replace('.', '').replace('.', '').replace('-', '').replace('/', '')
    await refetch()

    if (data?.resultado == true) {
      setShowAlert(1)
    } else {
      setShowAlert(2)
    }
  }
  return (
    <div className="wrapper wrapper-content  animated fadeInRight">
      <Row>
        <Col sm={6}>
          <Panel>
            <PanelHeader>
              <h5>Consultar cliente</h5>
            </PanelHeader>
            <PanelContent>
              <form onSubmit={handleSubmit((data) => consulta(data))}>
                <Row className="form-group">
                  <Text data-mask="cpfcnpj" label="CPF ou CNPJ" {...register('documento')} />
                </Row>
                <Row className="form-group">
                  <div className="col-sm-10 offset-sm-2">
                    <Button disabled={isLoading} label="Consultar" type="submit" variant="primary"></Button>
                  </div>
                </Row>
              </form>
              {showAlert > 0 && (
                <Row className="form-group">
                  {!error && (
                    <div className="col-sm-10 offset-sm-2">
                      <Label variant={data?.resultado == true ? 'success' : 'warning'}>
                        {data?.resultado == true ? 'Cliente cadastrado' : 'Cliente não cadastrado'}: {data?.documento}
                      </Label>
                    </div>
                  )}
                  {error && (
                    <div className="col-sm-10 offset-sm-2">
                      <Label variant="danger">Erro encontrado</Label>
                    </div>
                  )}
                </Row>
              )}
            </PanelContent>
          </Panel>
        </Col>
      </Row>
    </div>
  )
}
