import classNames from 'classnames'
import { ModalHeaderProps } from 'common'

export function ModalHeader(props: ModalHeaderProps) {
  const { icon, setOpen, title, ...divProps } = props

  const handleClose = () => {
    setOpen(false)
  }

  const className = classNames('modal-header', props.className)

  return (
    <div {...divProps} className={className}>
      <button type="button" className="close" onClick={handleClose}>
        <span aria-hidden="true">&times;</span>
        <span className="sr-only">Close</span>
      </button>
      {icon}
      <h4 className="modal-title">{title}</h4>
      {props.children}
    </div>
  )
}
