import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community'
import { httpGet } from 'common/services'

interface ZumaColor {
  id: number
  nome: string
  descricao: string
}

interface ProductColorSelectorParams extends ICellEditorParams {
  values: ZumaColor[]
}

export class ProductColorSelector implements ICellEditorComp {
  container: HTMLElement = document.createElement('div')
  filter: string = ''
  filterElement: HTMLInputElement | undefined
  items: ZumaColor[] | undefined
  params: ICellEditorParams = {} as ProductColorSelectorParams
  value: string = ''

  init(params: ProductColorSelectorParams) {
    this.container.setAttribute('style', 'width: 650px; height: 550px; background-color: #ffffff; overflow: scroll')
    this.container.innerHTML = `<div class="form-group m-sm"><input class="full-width" id="filter" type="text" placeholder="Filtrar" /></div><div id="items"></div>`
    this.filter = ''
    this.filterElement = this.container.querySelector('#filter') as HTMLInputElement
    this.params = params

    this.filterElement?.addEventListener('keyup', () => {
      this._updateItems()
    })

    if (params.values?.length > 0) {
      this.items = params.values
    } else {
      httpGet<ZumaColor[]>('/backend/zuma/colors').then((colors) => {
        this.items = colors.value

        this._updateItems()
      })
    }
  }

  afterGuiAttached() {
    this.filterElement?.focus()
  }

  destroy() {
    this._removeApplyListener()
  }

  getGui() {
    return this.container as HTMLElement
  }

  getValue() {
    return this.value
  }

  isPopup() {
    return true
  }

  _applyValue = (event: Event) => {
    this.value = ((event.target as HTMLDivElement).parentNode as HTMLLIElement).dataset.value || ''

    this.params.stopEditing()
  }

  _renderItem(item: ZumaColor) {
    return `<li class="dd-item" data-value="${item.id.toString().concat(';', item.nome)}")"><div class="dd-handle">${item.nome}${item.descricao ? ' - ' + item.descricao : ''}</div></li>`
  }

  _updateItems() {
    this.filter = this.filterElement?.value || ''

    const itemsElement = this.container.querySelector('#items') as HTMLDivElement

    itemsElement.innerHTML = `
          <div class="dd m-sm">
            <ol class="dd-list">
              ${
                this.items &&
                this.items.filter &&
                this.items
                  .filter((it: ZumaColor) => it.nome.toUpperCase().includes(this.filter.toUpperCase()))
                  .map((it: ZumaColor) => {
                    return this._renderItem(it)
                  })
                  .join('')
              }
            </ol>
          </div>`

    this._registerApplyListener()
  }

  _registerApplyListener() {
    const items = this.container.querySelectorAll('.dd-item')

    items?.forEach((element) => {
      element.addEventListener('click', this._applyValue)
    })
  }

  _removeApplyListener() {
    const items = this.container.querySelectorAll('.dd-item')

    items?.forEach((element) => {
      element.removeEventListener('click', this._applyValue)
    })
  }
}
