/* eslint-disable @typescript-eslint/no-explicit-any */
import { CellClickedEvent, CellKeyDownEvent } from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { forwardRef } from 'react'

const copyTextToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Copying to clipboard was successful!')
    },
    function (err) {
      console.error('Could not copy text: ', err)
      console.log('trying fallback')
      fallbackCopyTextToClipboard(text)
    },
  )
}

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    const msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

export const AgGridWithClipboard = forwardRef<AgGridReact, AgGridReactProps>((props, ref) => {
  const cellClicked = (event: CellClickedEvent) => {
    if (!event) {
      console.log(event)
    }
  }

  const cellKeyDown = (event: CellKeyDownEvent) => {
    if ((event.event as any).ctrlKey === true && (event.event as any).code == 'KeyC') {
      copyTextToClipboard(event.value)
    }
    if ((event.event as any).ctrlKey === true && (event.event as any).code == 'KeyV') {
      const colName = (event.column as any).colId

      navigator.clipboard.readText().then((value) => {
        const rowNode = event.node

        const gridRef = ref as any

        gridRef.current.api.dispatchEvent({
          ...event,
          type: 'cellEditingStarted',
        })

        rowNode.setDataValue(colName, value)

        gridRef.current.api.dispatchEvent({
          ...event,
          type: 'cellEditingStopped',
          valueChanged: true,
        } as any)
      })
    }
  }

  return <AgGridReact {...props} onCellClicked={cellClicked} onCellKeyDown={cellKeyDown} ref={ref} />
})
