import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { TemplateWrapper } from 'inspinia-template'
import { router } from './router'

import 'bootstrap/scss/bootstrap.scss'
import 'inspinia-template/less/style.less'

const queryClient = new QueryClient()

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <TemplateWrapper>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </TemplateWrapper>
  </StrictMode>,
)
