import { useState, useEffect, FormEvent } from 'react'
import { useDropzone } from 'react-dropzone'
import { formatBytes } from '../services/api'
import { toast } from 'react-toastify'
import { Button } from 'inspinia-template'
import { httpGet, httpPost } from 'common/services'

const thumbsContainer: Partial<React.CSSProperties> = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

const thumb: Partial<React.CSSProperties> = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 150,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner: Partial<React.CSSProperties> = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img: Partial<React.CSSProperties> = {
  display: 'block',
  width: 'auto',
  height: 100,
}

interface ImageFile {
  name: string
  preview: string
  size: number
}

export function UploadImagens() {
  const [files, setFiles] = useState<ImageFile[]>([])
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      let arquivosForaPadrao = 0
      acceptedFiles.forEach((file) => {
        if (file.name.indexOf('-') != 5) {
          arquivosForaPadrao++
          console.log('Nome do arquivo fora do padrão: ' + file.name)
        }
      })
      if (arquivosForaPadrao > 0) {
        toast.error(
          arquivosForaPadrao + (arquivosForaPadrao == 1 ? ' arquivo' : ' arquivos') + ' com o nome fora do padrão',
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            icon: true,
            theme: 'colored',
            onClose: () => {},
          },
        )
      }
      setFiles(
        acceptedFiles
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((file) => {
            return file.name.indexOf('-') == 5
          })
          .map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
      )
    },
  })

  const removeAll = () => {
    setFiles([])
  }

  const removeFile = (file: ImageFile) => () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(file), 1)
    setFiles(newFiles)
  }

  const upload = (file: ImageFile) => {
    const xhr = new XMLHttpRequest()
    const formData = new FormData()
    const method = 'POST'
    const sku = file.name.split('-')[0]
    const url = '/backend/zuma/upload/' + sku

    xhr.open(method, url, true)

    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    // update progress (can be used to show progress indicator)
    // xhr.upload.addEventListener('progress', (e) => {
    //   fileWithMeta.meta.percent = (e.loaded * 100.0) / e.total || 100
    //   this.forceUpdate()
    // })

    xhr.addEventListener('readystatechange', () => {
      // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
      if (xhr.readyState !== 2 && xhr.readyState !== 4) return

      // if (xhr.status === 0 && fileWithMeta.meta.status !== 'aborted') {
      //   fileWithMeta.meta.status = 'exception_upload'
      //   this.handleChangeStatus(fileWithMeta)
      //   this.forceUpdate()
      // }
      if (xhr.status > 0 && xhr.status < 400 && xhr.readyState == 4) {
        if (window.localStorage.getItem('lastimage-' + sku) == file.name) {
          window.localStorage.removeItem('lastimage-' + sku)
          httpGet(`/backend/zuma/export/${sku}/anymarket`)
        }
        toast.success('SKU ' + sku + ': imagem adicionada com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          icon: true,
          theme: 'colored',
          onClose: () => {},
        })
      }

      // if (xhr.status > 0 && xhr.status < 400) {
      //   fileWithMeta.meta.percent = 100
      //   if (xhr.readyState === 2) fileWithMeta.meta.status = 'headers_received'
      //   if (xhr.readyState === 4) fileWithMeta.meta.status = 'done'
      //   this.handleChangeStatus(fileWithMeta)
      //   this.forceUpdate()
      // }

      // if (xhr.status >= 400 && fileWithMeta.meta.status !== 'error_upload') {
      //   fileWithMeta.meta.status = 'error_upload'
      //   this.handleChangeStatus(fileWithMeta)
      //   this.forceUpdate()
      // }
    })

    formData.append('files', file)
    // if (this.props.timeout) xhr.timeout = this.props.timeout
    xhr.send(formData)
    // fileWithMeta.xhr = xhr
    // fileWithMeta.meta.status = 'uploading'
    // this.handleChangeStatus(fileWithMeta)
    // this.forceUpdate()
  }

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <div>
          <img
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview)
            }}
          />
          <button
            className="btn btn-xs btn-primary"
            onClick={removeFile(file)}
            style={{ position: 'relative', height: '20px', left: '68px', top: '-100px', paddingTop: 0 }}
          >
            x
          </button>
          <span>
            {file.name} - {formatBytes(file.size)}
          </span>
        </div>
      </div>
    </div>
  ))

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    // Now get the form data as you regularly would
    // const formData = new FormData(e.currentTarget)

    //const file = formData.get('my-file')
    let ant = ''

    files.forEach((file) => {
      console.log(file.name)
      const sku = file.name.split('-')[0]

      if (sku != ant) {
        const url = '/backend/zuma/images/removeAll/' + sku
        httpPost(url, {}).then(() => {
          files
            .filter((it) => {
              return it.name.startsWith(sku + '-')
            })
            .forEach((file) => {
              window.localStorage.setItem('lastimage-' + sku, file.name)
              upload(file)
            })
        })
        ant = sku
      }
    })
  }

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [files])

  return (
    <>
      <h1>Upload de imagens em massa</h1>
      <br />
      <br />
      <section className="container">
        <form onSubmit={handleSubmit}>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Arraste os arquivos para cá ou clique para selecionar arquivos no seu computador</p>
          </div>
          <aside style={thumbsContainer}>{thumbs}</aside>
          <Button variant="primary" className="m-r-sm" disabled={files.length == 0} type="submit">
            Enviar
          </Button>
          {files.length > 0 && (
            <Button variant="secondary" onClick={removeAll}>
              Limpar
            </Button>
          )}
        </form>
      </section>

      {/* <Dropzone name="my-file" required /> */}
    </>
  )
}
