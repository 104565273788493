import { useEffect, useState } from 'react'
import { httpPost } from 'common/services'
import { Col, Row } from '../design'
import { formatBytes, useRead } from '../services/api'
import { ProdutoZuma } from '../features/zuma/types'

export function ImagensProduto(props: { id: string }) {
  const readProduto = useRead<ProdutoZuma>('/zuma/products', props.id)

  // let produto: ProdutoZuma = {} as ProdutoZuma

  const [produto, setProduto] = useState<ProdutoZuma>({} as ProdutoZuma)

  const removeFile = (index: number) => () => {
    const image = produto.imagens[index]
    produto.imagens = produto.imagens.filter((it, idx) => {
      return idx != index
    })
    const sku = produto.codigoNoFornecedor

    const url = '/backend/zuma/images/remove/' + sku + '/' + image.Nome

    httpPost(url, {}).then(() => {
      setProduto(produto)
    })

    console.log(image)
  }

  useEffect(() => {
    if (!readProduto.isFetched && !readProduto.isFetching && props.id != '0') {
      readProduto.refetch().then((data) => {
        if (data.isSuccess && data.data) {
          setProduto(data.data)
        }
      })
    }
  }, [props.id])

  //   useEffect(() => {
  //     const produto = useRead('MultiversoProduto', props.id)
  //   }, [props.id])

  return (
    <Row>
      <h2>
        {produto.id} - {produto.titulo}
      </h2>
      <Col lg={12}>
        {produto &&
          produto.imagens &&
          produto.imagens.map((image, index) => {
            return (
              <div className="file-box">
                <div className="file">
                  <a href="#">
                    <span className="corner"></span>

                    <div className="image">
                      <img alt="image" className="img-fluid" src={image.url} style={{ maxHeight: '100%' }} />
                    </div>
                    <button
                      className="btn btn-xs btn-primary"
                      onClick={removeFile(index)}
                      style={{ position: 'relative', height: '20px', left: '176px', top: '-100px', paddingTop: 0 }}
                    >
                      x
                    </button>
                    <div className="file-name">
                      {image.Nome}
                      <br />
                      <small>Tamanho: {formatBytes(image.TamanhoBytes!)}</small>
                    </div>
                  </a>
                </div>
              </div>
            )
          })}
      </Col>
    </Row>
  )
}
