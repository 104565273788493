/* eslint-disable  @typescript-eslint/no-explicit-any */
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { Button, Col, Panel, PanelContent, PanelHeader, Row, Text } from 'inspinia-template'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import JSONPretty from 'react-json-pretty'
import z from 'zod'
import Inputmask from 'inputmask'
import { httpPost } from 'common/services'
import { backendURL } from '../../services/api'

interface EnviarCobranca {
  orcamento: string
  documento: string
  valor: string
}

const enviarCobrancaSchema = z.object({
  orcamento: z.string(),
  documento: z.string().min(14).max(18),
  valor: z.string(),
})

type EnviarCobrancaSchema = z.infer<typeof enviarCobrancaSchema>

const postCobranca = (cobranca: EnviarCobranca) =>
  httpPost<any>(`${backendURL}/blu/payment/`, cobranca)
    .then((ret) => {
      return ret.value
    })
    .catch((reason) => {
      console.log(reason)
      if (reason === 401) {
        const navigate = useNavigate()
        navigate('/login')
      }
    })

export function EnviarCobranca() {
  const { register, handleSubmit } = useForm<EnviarCobrancaSchema>({ resolver: zodResolver(enviarCobrancaSchema) })
  const navigate = useNavigate()
  const { cpfcnpj, prevenda } = useParams()

  // const [orcamento, setOrcamento] = useState(prevenda || "")
  // const [documento, setDocumento] = useState(cpfcnpj || "")
  // const [valor, setValor] = useState("")

  const [showResult, setShowResult] = useState(false)

  const { data, isPending, mutate } = useMutation({
    retry: false,
    mutationFn: postCobranca,
    onSuccess: (res) => {
      console.log(res)
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const enviar = async (data: EnviarCobrancaSchema) => {
    data.valor = Inputmask.unmask(data.valor, { alias: 'reais' })
    data.valor = data.valor.replace(',', '.')
    console.log(data.valor)

    await mutate({ orcamento: data.orcamento, documento: data.documento, valor: data.valor })
    setShowResult(true)
  }
  return (
    <div className="wrapper wrapper-content  animated fadeInRight">
      <Row>
        <Col sm={6}>
          <Panel>
            <PanelHeader>
              <h5>Enviar cobrança</h5>
            </PanelHeader>
            <PanelContent>
              <form onSubmit={handleSubmit(enviar)}>
                <Row className="form-group">
                  <Text {...register('orcamento')} label="Pré-Venda" defaultValue={prevenda} />
                </Row>
                <Row className="form-group">
                  <Text {...register('documento')} data-mask="cpfcnpj" label="CPF ou CNPJ" defaultValue={cpfcnpj} />
                </Row>
                <Row className="form-group">
                  <Text {...register('valor')} data-mask="reais" label="Valor" />
                </Row>

                <Row className="form-group">
                  <div className="col-sm-10 offset-sm-2">
                    <Button disabled={isPending} label="Enviar" type="submit" variant="primary"></Button>
                  </div>
                </Row>
              </form>
              {showResult && (
                <>
                  <JSONPretty id="json-pretty" data={data}></JSONPretty>
                  <br />
                  {data?.uuid && (
                    <Button variant="secondary" onClick={() => navigate(`/consultar-cobranca/${data.uuid}`)}>
                      Consultar cobrança
                    </Button>
                  )}
                </>
              )}
            </PanelContent>
          </Panel>
        </Col>
      </Row>
    </div>
  )
}
