import { AG_GRID_LOCALE_PT } from '@ag-grid-community/locale'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Button, Col, Panel, PanelContent, Row, Tab, TabSet } from 'inspinia-template'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FaCalendar } from 'react-icons/fa'
import { useListAll } from '../../services/api'
import ReactExport from 'react-export-excel-ext'
import { httpPost } from 'common/services'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export function Estatisticas() {
  const gridRef = useRef<AgGridReact>(null)

  const localeText = AG_GRID_LOCALE_PT

  const pagination = true
  const paginationPageSize = 25
  const paginationPageSizeSelector = [25, 50, 100, 200]

  const [dataInicial1, setDataInicial1] = useState('01/10/2024')
  const [dataFinal1, setDataFinal1] = useState('24/10/2024')
  const [dataInicial2, setDataInicial2] = useState('01/10/2024')
  const [dataFinal2, setDataFinal2] = useState('24/10/2024')

  const contacts = httpPost('/backend/szchat/contacts', { dataInicial: dataInicial1, dataFinal: dataFinal1 })
  const [rowData1, setRowData1] = useState([])

  useEffect(() => {
    contacts.then((data) => {
      setRowData1(data.value)
    })
  }, [dataInicial1, dataFinal1])

  const products = httpPost('/backend/szchat/products', { dataInicial: dataInicial2, dataFinal: dataFinal2 })
  const [rowData2, setRowData2] = useState([])

  useEffect(() => {
    products.then((data) => {
      setRowData2(data.value)
    })
  }, [dataInicial2, dataFinal2])

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      editable: false,
      filter: true,
      floatingFilter: false,
    }
  }, [])

  const [colDefs1] = useState<ColDef[]>([
    {
      field: 'name',
      headerName: 'Nome',
    },
    { field: 'phone', headerName: 'Número' },
    { field: 'platform', headerName: 'Plataforma' },
    {
      field: 'count',
      headerName: 'Quantidade de mensagens',
    },
  ])

  const [colDefs2] = useState<ColDef[]>([
    {
      field: 'sku',
      headerName: 'Sku',
    },
    { field: 'name', headerName: 'Nome' },
    { field: 'count', headerName: 'Quantidade de consultas' },
  ])
  return (
    <TabSet className="m-t-md">
      <Tab label="Contatos mais ativos">
        <Panel>
          <PanelContent>
            <Row>
              <Col sm={4}>
                <div className="form-group">
                  <label className="col-form-label" htmlFor="date_start1">
                    Data inicial
                  </label>
                  <div className="input-group date">
                    <span className="input-group-addon">
                      <FaCalendar />
                    </span>
                    <input
                      id="date_start1"
                      type="text"
                      className="form-control"
                      defaultValue="01/10/2024"
                      onBlur={(e) => setDataInicial1(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="form-group">
                  <label className="col-form-label" htmlFor="date_end1">
                    Data final
                  </label>
                  <div className="input-group date">
                    <span className="input-group-addon">
                      <FaCalendar />
                    </span>
                    <input
                      id="date_end1"
                      type="text"
                      className="form-control"
                      defaultValue={new Date().toLocaleDateString()}
                      onBlur={(e) => setDataFinal1(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="form-group">
                  <label className="col-form-label" htmlFor="date_modified">
                    &#160;
                  </label>
                  <div className="input-group">
                    <Button label="Atualizar" variant="default" className="m-r-xs" />

                    <ExcelFile
                      filename={`Contatos mais ativos entre ${dataInicial1} e ${dataFinal1}`}
                      element={<Button label="Exportar" variant="default" />}
                    >
                      <ExcelSheet data={rowData1} name="Contatos mais ativos">
                        <ExcelColumn label="Nome" value="name" />
                        <ExcelColumn label="Telefone" value="phone" />
                        <ExcelColumn label="Plataforma" value="platform" />
                        <ExcelColumn label="Quantidade de consultas" value="count" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </Col>
            </Row>
          </PanelContent>
        </Panel>
        {/* <Panel></Panel> */}
        <div
          className="ag-theme-quartz" // applying the Data Grid theme
          style={{ height: '700px' }} // the Data Grid will fill the size of the parent container
        >
          <AgGridReact
            autoSizeStrategy={{
              type: 'fitCellContents',
            }}
            className="m-t-sm"
            columnDefs={colDefs1}
            defaultColDef={defaultColDef}
            localeText={localeText}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            ref={gridRef}
            rowData={rowData1}
          />
        </div>
      </Tab>
      <Tab label="Produtos mais consultados">
        <Panel>
          <PanelContent>
            <Row>
              <Col sm={4}>
                <div className="form-group">
                  <label className="col-form-label" htmlFor="date_start2">
                    Data inicial
                  </label>
                  <div className="input-group date">
                    <span className="input-group-addon">
                      <FaCalendar />
                    </span>
                    <input
                      id="date_start2"
                      type="text"
                      className="form-control"
                      defaultValue="01/10/2024"
                      onBlur={(e) => setDataInicial2(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="form-group">
                  <label className="col-form-label" htmlFor="date_end2">
                    Data final
                  </label>
                  <div className="input-group date">
                    <span className="input-group-addon">
                      <FaCalendar />
                    </span>
                    <input
                      id="date_end2"
                      type="text"
                      className="form-control"
                      defaultValue={new Date().toLocaleDateString()}
                      onBlur={(e) => setDataFinal2(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="form-group">
                  <label className="col-form-label" htmlFor="date_modified">
                    &#160;
                  </label>
                  <div className="input-group">
                    <Button label="Atualizar" variant="default" className="m-r-xs" />

                    <ExcelFile
                      filename={`Produtos mais consultados entre ${dataInicial2} e ${dataFinal2}`}
                      element={<Button label="Exportar" variant="default" />}
                    >
                      <ExcelSheet data={rowData2} name="Produtos mais consultados">
                        <ExcelColumn label="Sku" value="sku" />
                        <ExcelColumn label="Nome" value="name" />
                        <ExcelColumn label="Quantidade de consultas" value="count" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </Col>
            </Row>
          </PanelContent>
        </Panel>
        {/* <Panel></Panel> */}
        <div
          className="ag-theme-quartz" // applying the Data Grid theme
          style={{ height: '700px' }} // the Data Grid will fill the size of the parent container
        >
          <AgGridReact
            autoSizeStrategy={{
              type: 'fitCellContents',
            }}
            className="m-t-sm"
            columnDefs={colDefs2}
            defaultColDef={defaultColDef}
            localeText={localeText}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            ref={gridRef}
            rowData={rowData2}
          />
        </div>
      </Tab>
    </TabSet>
  )
}
