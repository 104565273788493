/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community'

interface CustomEditorParams extends ICellEditorParams {
  setShowModal?: (value: boolean) => void
  editorRef: any
  gridRef: any
}

export class CustomEditor implements ICellEditorComp {
  applyButton: any
  container: any
  params: any
  value: any

  // onKeyDown(event: any) {
  //   const key = event.key
  // }

  init(params: CustomEditorParams) {
    console.log('parametros recebidos')
    console.log(params)
    this.params = params

    if (params.setShowModal) {
      params.setShowModal(true)
    }

    this.value = params.value

    if (this.params.editorRef.current) {
      this.params.editorRef.current.setContent(params.value)
    }

    this.applyButton = document.querySelector('#apply-editor')
    this.applyButton.addEventListener('click', this._applyValue)

    this.container = document.createElement('div')
  }

  afterGuiAttached() {}

  destroy() {}

  getGui() {
    return this.container
  }

  getValue() {
    console.log('getting value: ' + this.value)
    return this.value
  }

  isPopup() {
    return true
  }

  refresh(params: ICellEditorParams) {
    console.log('refreshing')
    console.log(params)
  }

  _applyValue = (element: any) => {
    console.log(element)
    this.value = this.params.editorRef.current.getContent().toString()
    console.log('setting value to: ' + this.value)

    const colName = (this.params.column as any).colId
    const rowNode = this.params.node
    console.log(this.params.event)
    this.params.gridRef.current.api.dispatchEvent({
      ...this.params.event,
      type: 'cellEditingStarted',
    })
    console.log('setting ' + colName + ' to ' + this.value)
    rowNode.setDataValue(colName, this.value)
    this.params.gridRef.current.api.dispatchEvent({
      ...this.params.event,
      type: 'cellEditingStopped',
      valueChanged: true,
    } as any)

    this.params.setShowModal(false)
    this.applyButton.removeEventListener('click', this._applyValue)

    this.params.stopEditing()
  }
}
