import { createContext } from 'react'

interface AuthContextType {
  user: User
  hasMFA: boolean
  isMFAVerified: boolean
  cookies: unknown
  login: (name: string, password: string) => Promise<boolean>
  logout: () => Promise<void>
  verifyMFACode: (code: string) => Promise<boolean>
  //  check: () => void
}

export type User = {
  ID: number
  CreatedAt: Date
  UpdatedAt: Date
  DeletedAt: Date | null
  Uuid: string
  Nome: string
  Email: string
  Senha: string
  Status: number
  Grupo: number
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)
