import { useQuery } from '@tanstack/react-query'
import { Button, Col, Panel, PanelContent, PanelHeader, Row, Text } from 'inspinia-template'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import JSONPretty from 'react-json-pretty'
import { httpDelete } from 'common/services'
import { backendURL } from '../../services/api'

export function CancelarCobranca() {
  const navigate = useNavigate()

  const { id } = useParams()

  const [uuid, setUuid] = useState(id)
  const [showResult, setShowResult] = useState(id !== undefined)

  const { data, refetch } = useQuery({
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    queryKey: ['cobranca-cancelar', uuid],
    queryFn: async ({ signal }) =>
      httpDelete<boolean>(`${backendURL}/blu/payment/${uuid}`, { signal })
        .then((res) => {
          // if (!res.ok) {
          //     setShowResult(false)
          //     throw new Error('Failed to fetch')
          // }

          return res.value
        })
        .catch((reason) => {
          console.log(reason)
          if (reason === 401) {
            navigate('/login')
          }
        }),
  })

  const consulta = async () => {
    await refetch()
    setShowResult(true)
  }

  return (
    <div className="wrapper wrapper-content  animated fadeInRight">
      <Row>
        <Col sm={6}>
          <Panel>
            <PanelHeader>
              <h5>Cancelar cobrança</h5>
            </PanelHeader>
            <PanelContent>
              <Row className="form-group">
                <Text label="UUID" name="uuid" onChange={(event) => setUuid(event.target.value)} defaultValue={id} />
              </Row>
              <Row className="form-group">
                <div className="col-sm-10 offset-sm-2">
                  <Button label="Cancelar" variant="danger" onClick={consulta}></Button>
                </div>
              </Row>
              {showResult && (
                <>
                  <JSONPretty id="json-pretty" data={data}></JSONPretty>
                  <br />
                </>
              )}
            </PanelContent>
          </Panel>
        </Col>
      </Row>
    </div>
  )
}
