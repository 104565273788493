import classNames from 'classnames'
import { ModalContentProps } from 'common'

export function ModalContent(props: ModalContentProps) {
  const className = classNames('modal-body', props.className)

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  )
}
