/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ReactElement, ReactNode } from 'react'
import { useState } from 'react'
import nl2br from 'react-nl2br'

export type GridRowProps = {
  attributes: unknown[]
  children?: ReactElement | ReactElement[] | ReactNode | unknown
  toggle?: boolean
  toggleColumn?: string
  values: unknown
  hiddenColumns?: unknown[]
}

export function GridRow(props: GridRowProps) {
  const [toggle, setToggle] = useState(false)
  const index = 0

  const extract = (pArray: any, pIndex: string): string => {
    if (pArray === undefined || pArray === null || pArray.length === 0) {
      return ''
    }
    if (undefined !== pArray[pIndex]) {
      return pArray[pIndex]
    } else {
      const spl = pIndex.split('.', 2)

      if (undefined !== pArray[spl[0]] && undefined !== pArray[spl[0]][0]) {
        return extract(pArray[spl[0]][0], spl[1])
      } else return extract(pArray[spl[0]], spl[1])
    }
  }

  return (
    <>
      <tr key={index}>
        {props.attributes.map((attribute: any, atindex) => (
          <td
            key={atindex}
            className={
              attribute.class + (props.hiddenColumns && props.hiddenColumns.includes(attribute.name) ? ' d-none' : '')
            }
          >
            {props.toggle && props.toggleColumn !== 'last' && atindex === 0 && (
              <span className="footable-toggle" onClick={() => setToggle(!toggle)}></span>
            )}
            {extract(props.values, attribute.name + (attribute.type?.substring(0, 6) === 'model:' ? '.dn' : ''))}
          </td>
        ))}
        <td className="text-right">{props.children}</td>
      </tr>
      {toggle && (
        <tr className="footable-row-detail">
          <td className="footable-row-detail-cell" colSpan={9}>
            <div className="footable-row-detail-inner">
              {props.hiddenColumns?.map((column) => (
                <div className="footable-row-detail-row">
                  <div className="footable-row-detail-name">
                    {
                      (
                        props.attributes.filter((att: any) => {
                          return att.name === column
                        })[0] as any
                      ).title
                    }
                    :
                  </div>
                  <div className="footable-row-detail-value">{nl2br(extract(props.values, column))}</div>
                </div>
              ))}
            </div>
          </td>
        </tr>
      )}
    </>
  )
}
