import classNames from 'classnames'
import { getShape, getVariant, getVariantText, LabelProps } from 'common'

export function Label(props: LabelProps) {
  const { shape, variant, ...spanProps } = props

  const className = classNames(
    'label',
    getShape(shape),
    getVariant('label-', variant),
    getVariantText(variant),
    props.className,
  )

  return (
    <span {...spanProps} className={className}>
      {props.children}
    </span>
  )
}
