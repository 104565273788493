import { Modal, ModalContent, ModalFooter, ModalHeader } from '../design'
import { ImagensProduto } from './ImagensProduto'

export function ModalFotos(props: {
  id: string
  isOpen: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  return (
    <Modal isOpen={props.isOpen} setOpen={props.setOpen} style={{ minWidth: '720px' }}>
      <ModalHeader title="Fotos" setOpen={props.setOpen}></ModalHeader>
      <ModalContent style={{ width: '720px' }}>
        <div className="block fullwidth" style={{ minHeight: 60 }}>
          Drop zone
        </div>
        <div className="m-t-md">
          <ImagensProduto id={props.id} />
        </div>
      </ModalContent>
      <ModalFooter></ModalFooter>
    </Modal>
  )
}
