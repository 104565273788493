export interface HttpResponse<T> extends Response {
  value?: T
}

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  return await fetch(request, { credentials: 'include', redirect: 'follow' })
    .then(async (response: HttpResponse<T>) => {
      if (response.status === 200) {
        try {
          response.value = await response.json()
        } catch (exception) {
          console.error('Uncaught JSON exception: ', exception)
        }
      }

      if (!response.ok) {
        console.error(response)
        return Promise.reject(response.status)
      }

      return response
    })
    .catch((error) => {
      console.error('There was an error!', error)
      return Promise.reject(error)
    })
}

export async function httpDelete<T>(path: string, args: RequestInit = {}): Promise<HttpResponse<T>> {
  args.headers = { 'Content-Type': 'application/json', ...args.headers }
  args.method = 'delete'
  return await http<T>(new Request(path, args))
}

export async function httpGet<T>(path: string, args: RequestInit = {}): Promise<HttpResponse<T>> {
  args.headers = { 'Content-Type': 'application/json', ...args.headers }
  args.method = 'get'
  return await http<T>(new Request(path, args))
}

export async function httpPatch<T>(path: string, body: unknown, args: RequestInit = {}): Promise<HttpResponse<T>> {
  args.body = JSON.stringify(body)
  args.method = 'PATCH'
  args.headers = { 'Content-Type': 'application/json', ...args.headers }
  return await http<T>(new Request(path, args))
}

export async function httpPost<T>(path: string, body: unknown, args: RequestInit = {}): Promise<HttpResponse<T>> {
  args.body = JSON.stringify(body)
  args.method = 'post'
  args.headers = { 'Content-Type': 'application/json', ...args.headers }
  return await http<T>(new Request(path, args))
}

export async function httpPut<T>(path: string, body: unknown, args: RequestInit = {}): Promise<HttpResponse<T>> {
  args.body = JSON.stringify(body)
  args.headers = { 'Content-Type': 'application/json', ...args.headers }
  args.method = 'put'

  return await http<T>(new Request(path, args))
}
