import classNames from 'classnames'
import { RowProps } from 'common'

export function Row(props: RowProps) {
  const className = classNames('row', props.className)

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  )
}
