import { forwardRef, useEffect } from 'react'
import Inputmask from 'inputmask'
import { TextProps } from 'common'

Inputmask.extendAliases({
  reais: {
    prefix: 'R$ ',
    groupSeparator: '.',
    radixPoint: ',',
    alias: 'numeric',
    placeholder: '0',
    digits: 2,
    digitsOptional: false,
    clearMaskOnLostFocus: true,
    substituteRadixPoint: true,
    rightAlign: false,
  },
})

export const Text = forwardRef<HTMLInputElement, TextProps>((props, ref) => {
  const { help, label, vertical = false, ...inputProps } = props

  useEffect(() => {
    if (props['data-mask']) {
      if (props['data-mask'] === 'cpfcnpj') {
        Inputmask({
          importDataAttributes: false,
          keepStatic: true,
          mask: ['999.999.999-99', '99.999.999/9999-99'],
        }).mask(`#${props.id || props.name}`)
      }
      if (props['data-mask'] === 'currency') {
        Inputmask({
          alias: 'currency',
        }).mask(`#${props.id || props.name}`)
      }
      if (props['data-mask'] === 'reais') {
        Inputmask({
          alias: 'reais',
        }).mask(`#${props.id || props.name}`)
      } else {
        Inputmask().mask(document.querySelectorAll(`#${props.id || props.name}`))
      }
    }
  }, [props['data-mask'], props.id, props.name])

  return (
    <>
      {label && <label className={`${!vertical ? 'col-sm-2' : ''} col-form-label`}>{label}</label>}
      <div className={label && !vertical ? 'col-sm-10' : ''}>
        <input
          {...inputProps}
          className={`form-control${props.className ? ` ${props.className}` : ''}`}
          id={props.id || props.name}
          ref={ref}
          type={props.type || 'text'}
        />
        {help && <span className="form-text m-b-none">{help}</span>}
        <div className="invalid-feedback">Campo obrigatório!</div>
      </div>
    </>
  )
})
