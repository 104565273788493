import { useQuery } from '@tanstack/react-query'
import { httpGet } from 'common/services'
import { Button, Col, Panel, PanelContent, PanelHeader, Row } from 'inspinia-template'
import { useNavigate } from 'react-router-dom'
import { backendURL } from '../../services/api'

export type MonitorItem = {
  ID: number
  CreatedAt: string
  UpdatedAt: string
  DeletedAt: string
  Uuid: string
  Cliente: string
  CpfCnpj: string
  Valor: number
  DataUltimaVerificacao: number
  StatusLocal: number
  Status: string
  StatusCode: number
  Orcamento: number
}

export type Stats = {
  Enviadas: number
  Autorizadas: number
  Rejeitadas: number
  ValorAprovado: number
}

export function Monitor() {
  const navigate = useNavigate()

  const { data } = useQuery({
    queryKey: ['monitor'],
    queryFn: ({ signal }) =>
      httpGet<MonitorItem[]>(`${backendURL}/blu/monitor/`, { signal }).then(
        (res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch')
          }

          return res.value
        },
        (reason) => {
          console.error(reason)
          if (reason === 401) {
            navigate('/login')
          }
          return Array<MonitorItem>()
        },
      ),
  })

  const stats = useQuery({
    queryKey: ['stats'],
    queryFn: ({ signal }) =>
      httpGet<Stats>(`${backendURL}/blu/status`, { signal }).then(
        (res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch')
          }

          return res.value
        },
        (reason) => {
          console.log(reason)
        },
      ),
  })

  return (
    <div className="wrapper wrapper-content  animated fadeInRight">
      <Row>
        <Col lg={3}>
          <Panel>
            <PanelHeader>Cobranças enviadas</PanelHeader>
            <PanelContent>
              <h2>{stats.data?.Enviadas}</h2>
              <div>
                <small>Quantidade enviada hoje</small>
              </div>
            </PanelContent>
          </Panel>
        </Col>
        <Col lg={3}>
          <Panel>
            <PanelHeader>Cobranças autorizadas</PanelHeader>
            <PanelContent>
              <h2>{stats.data?.Autorizadas}</h2>
              <div>
                <small>Quantidade autorizada hoje</small>
              </div>
            </PanelContent>
          </Panel>
        </Col>
        <Col lg={3}>
          <Panel>
            <PanelHeader>Cobranças rejeitadas</PanelHeader>
            <PanelContent>
              <h2>{stats.data?.Rejeitadas}</h2>
              <div>
                <small>Quantidade recusada hoje</small>
              </div>
            </PanelContent>
          </Panel>
        </Col>
        <Col lg={3}>
          <Panel>
            <PanelHeader>Total autorizado</PanelHeader>
            <PanelContent>
              <h2>{stats.data?.ValorAprovado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h2>
              <div>
                <small>Valor total autorizado hoje</small>
              </div>
            </PanelContent>
          </Panel>
        </Col>
      </Row>
      <Panel>
        <PanelHeader>Cobranças pendentes</PanelHeader>
        <PanelContent>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>UUID Blu</th>
                <th>Cliente</th>
                <th>Cobrado</th>
                <th>Pré-Venda</th>
                <th>Valor</th>
                <th>Status Blu</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.map((value) => (
                  <tr key={value.ID}>
                    <td>{value.ID}</td>
                    <td>{value.Uuid}</td>
                    <td>{value.Cliente}</td>
                    <td>{value.CpfCnpj}</td>
                    <td>{value.Orcamento}</td>
                    <td>{value.Valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                    <td>{value.Status}</td>
                    <td>
                      <Button
                        size="mini"
                        variant="secondary"
                        onClick={() => navigate(`/blu/consultar-cobranca/${value.Uuid}`)}
                      >
                        Interromper
                      </Button>
                      &#160;
                      <Button
                        size="mini"
                        variant="secondary"
                        onClick={() => navigate(`/blu/consultar-cobranca/${value.Uuid}`)}
                      >
                        Consultar
                      </Button>
                      &#160;
                      {value.StatusCode == 4 && (
                        <Button
                          size="mini"
                          variant="warning"
                          onClick={() =>
                            navigate(
                              `/blu/enviar-cobranca/${value.CpfCnpj.replace('.', '').replace('.', '').replace('-', '').replace('/', '')}`,
                            )
                          }
                        >
                          Reenviar
                        </Button>
                      )}
                      {value.StatusCode == 5 && (
                        <Button
                          size="mini"
                          variant="warning"
                          onClick={() =>
                            navigate(
                              `/blu/enviar-cobranca/${value.CpfCnpj.replace('.', '').replace('.', '').replace('-', '').replace('/', '')}/${value.Orcamento}`,
                            )
                          }
                        >
                          Reenviar
                        </Button>
                      )}
                      {value.StatusCode < 3 && (
                        <Button
                          size="mini"
                          variant="danger"
                          onClick={() => navigate(`/blu/cancelar-cobranca/${value.Uuid}`)}
                        >
                          Cancelar
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </PanelContent>
      </Panel>
    </div>
  )
}
