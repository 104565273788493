import { TextAreaProps } from 'common'
import { forwardRef } from 'react'

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ help, label, vertical = false, ...props }, ref) => {
    return (
      <>
        {label && <label className={`${!vertical ? 'col-sm-2' : ''} col-form-label`}>{label}</label>}
        <div className={label && !vertical ? 'col-sm-10' : ''}>
          <textarea
            {...props}
            className={`form-control${props.className ? ` ${props.className}` : ''}`}
            id={props.id || props.name}
            ref={ref}
          />
          {help && <span className="form-text m-b-none">{help}</span>}
          <div className="invalid-feedback">Campo obrigatório!</div>
        </div>
      </>
    )
  },
)
