import { useContext } from 'react'
import classNames from 'classnames'
import { CardContext, CardHeaderProps } from 'common'

export function CardHeader(props: CardHeaderProps) {
  const { title, ...divProps } = props

  const className = classNames('panel-heading', props.className)

  const context = useContext(CardContext)

  const handleTitleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    context?.setCollapsed(!context.isCollapsed)
    event.preventDefault()
  }

  return (
    <div className={className} {...divProps}>
      {context?.collapsible && (
        <h5 className="panel-title">
          <a data-toggle="collapse" href="#" onClick={(event) => handleTitleClick(event)}>
            {title}
            {props.children}
          </a>
        </h5>
      )}
      {!context?.collapsible && (
        <>
          {title}
          {props.children}
        </>
      )}
    </div>
  )
}
