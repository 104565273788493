import { useQuery } from '@tanstack/react-query'
import { Button, Panel, PanelContent, PanelHeader, Row, Text } from 'inspinia-template'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import JSONPretty from 'react-json-pretty'
import { httpGet } from 'common/services'
import { backendURL } from '../../services/api'

type CobrancaResponse = {
  documentNumber: string
  uuid: string
  billingLater: boolean
  charger: string
  charged: string
  status: string
  statusCode: number
  message: string
  kind: string
  chargerKind: string
  scheduledAt: string
  increaseOrDiscountRate: string
  increaseOrDiscount: string
  value: string
  chargerIncreaseOrDiscountRate: string
  chargerIncreaseOrDiscount: string
  chargerValue: string
  installmentsOfCharger: unknown
  grossValue: string
  netValue: string
  rateValue: string
  taxRate: string
  advancesRateValue: string
}

export function ConsultarCobranca() {
  const navigate = useNavigate()

  const { id } = useParams()

  const [uuid, setUuid] = useState(id)
  const [showResult, setShowResult] = useState(id !== undefined)

  const { data, refetch } = useQuery({
    enabled: id !== undefined,
    queryKey: ['cobranca', uuid],
    queryFn: async ({ signal }) =>
      httpGet<CobrancaResponse>(`${backendURL}/blu/payment/${uuid}`, { signal })
        .then((res) => {
          if (!res.ok) {
            setShowResult(false)
            throw new Error('Failed to fetch')
          }

          return res.value
        })
        .catch((reason) => {
          console.log(reason)
          if (reason === 401) {
            navigate('/login')
          }
        }),
  })

  const consulta = async () => {
    await refetch()
    setShowResult(true)
  }
  return (
    <>
      <div className="wrapper wrapper-content  animated fadeInRight">
        <div className="row">
          <div className="col-sm-6">
            <Panel>
              <PanelHeader>
                <h5>Consultar cobrança</h5>
              </PanelHeader>
              <PanelContent>
                <div className="form-group row">
                  <Text label="UUID" name="uuid" onChange={(event) => setUuid(event.target.value)} defaultValue={id} />
                </div>
                <div className="form-group row">
                  <div className="col-sm-10 offset-sm-2">
                    <Button label="Consultar" variant="primary" onClick={consulta}></Button>
                  </div>
                </div>
                {showResult && (
                  <>
                    <Row>
                      <Panel>
                        <PanelHeader>{data?.documentNumber}</PanelHeader>
                        {data?.charged}
                        <br />
                        R$ {data?.value.toLocaleString()}
                        <br />
                        Status: {data?.statusCode} - {data?.status}
                        <br />
                      </Panel>
                    </Row>
                    <div className="">
                      <JSONPretty id="json-pretty" data={data}></JSONPretty>
                      <br />
                      {data?.uuid && data?.statusCode < 3 && (
                        <>
                          <Button variant="secondary" onClick={() => navigate(`/blu/monitorar-cobranca/${data.uuid}`)}>
                            Monitorar cobrança
                          </Button>
                          &#160;&#160;
                          <Button variant="danger" onClick={() => navigate(`/blu/cancelar-cobranca/${data.uuid}`)}>
                            Cancelar cobrança
                          </Button>
                        </>
                      )}
                    </div>
                  </>
                )}
              </PanelContent>
            </Panel>
          </div>
        </div>
      </div>
    </>
  )
}
