export const BasicCellRenderer = (params: any) => {
  return params.value
}

export const CompoundValueCellRenderer = (params: any) => {
  if (params.value) {
    return (params.value as string).split(';')[1]
  } else if (params.data.value !== '') {
    return params.data.value
  }
}
