import classNames from 'classnames'
import { TabProps } from 'common'

export function Tab(props: TabProps) {
  const className = classNames('panel-body', props.className)

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  )
}
