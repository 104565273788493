import { useContext } from 'react'
import classNames from 'classnames'
import { PanelContext, PanelHeaderProps } from 'common'

export function PanelHeader(props: PanelHeaderProps) {
  const className = classNames('ibox-title', props.className)
  const context = useContext(PanelContext)

  return (
    <div {...props} className={className}>
      {context?.collapsible && (
        <a
          data-toggle="collapse"
          data-parent="#accordion"
          onClick={() => {
            context?.setCollapsed(!context.isCollapsed)
          }}
        >
          {props.children}
        </a>
      )}
      {!context?.collapsible && props.children}
    </div>
  )
}
