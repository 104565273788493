import { PageWrapper } from 'inspinia-template'
import { ReactNode } from 'react'
import { Sidebar } from './Sidebar'
import { ToastContainer } from 'react-toastify'

export type LayoutProps = {
  children?: ReactNode
  element?: ReactNode
}

export function Layout(props: LayoutProps) {
  document.body.classList.remove('login')

  return (
    <>
      <Sidebar />
      <PageWrapper>
        {/* <Header /> */}
        {props.children}
        {props.element}
        {/* <Footer /> */}
      </PageWrapper>
      <ToastContainer />
    </>
  )
}
