import { useContext } from 'react'
import classNames from 'classnames'
import { CardContentProps, CardContext } from 'common'

export function CardContent(props: CardContentProps) {
  const className = classNames('panel-body', props.className)

  const context = useContext(CardContext)

  return (
    <>
      {context?.collapsible && (
        <div {...props} className={`panel-collapse ${context.isCollapsed ? 'collapse' : 'collapse show'}`}>
          <div className={className}>{props.children}</div>
        </div>
      )}
      {!context?.collapsible && (
        <div {...props} className={className}>
          {props.children}
        </div>
      )}
    </>
  )
}
