import { PropsWithChildren } from 'react'
import { useLocalStorage } from 'common/hooks'
import { SettingsContext } from './SettingsContext'

export const SettingsProvider = (props: PropsWithChildren) => {
  const [showSidebar, setShowSidebar] = useLocalStorage('settings', null)

  // useEffect(() => {
  //   if (showSidebar) {
  //     document.body.classList.remove('mini-navbar')
  //   } else {
  //     document.body.classList.add('mini-navbar')
  //   }
  // }, [showSidebar])

  return <SettingsContext.Provider value={{ showSidebar, setShowSidebar }}>{props.children}</SettingsContext.Provider>
}
