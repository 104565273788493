import { ReactNode, useState } from 'react'

export type SidebarNavigationGroupProps = {
  collapsedIcon: ReactNode
  children?: ReactNode
  expandedIcon: ReactNode
  name?: string
  icon?: ReactNode
  label: string
}

export function SidebarNavigationGroup(props: SidebarNavigationGroupProps) {
  const [groupActive, setGroupActive] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setGroupActive(!groupActive)
    event.preventDefault()
  }

  return (
    <li className={groupActive ? 'active' : ''}>
      <a href="#" onClick={handleClick}>
        {props.icon}
        <span className="nav-label">{props.label}</span>
        {groupActive && props.expandedIcon}
        {!groupActive && props.collapsedIcon}
      </a>
      <ul className={`nav nav-second-level collapse ${groupActive ? 'in' : ''}`}>{props.children}</ul>
    </li>
  )
}
