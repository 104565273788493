import classNames from 'classnames'
import { PageWrapperProps } from 'common'

export function PageWrapper(props: PageWrapperProps) {
  const className = classNames('gray-bg', props.className)

  return (
    <div {...props} id="page-wrapper" className={className}>
      {props.children}
    </div>
  )
}
