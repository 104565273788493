import { useState } from 'react'
import classNames from 'classnames'
import { CardContext, CardProps, getVariant, getVariantText } from 'common'

export function Card(props: CardProps) {
  const className = classNames(
    'panel',
    getVariant('panel-', props.variant),
    getVariantText(props.variant),
    props.className,
  )
  const collapsible = props.collapsible ?? false
  const [isCollapsed, setCollapsed] = useState<boolean>(props.collapsed ?? false)

  return (
    <CardContext.Provider key={props.id} value={{ collapsible, isCollapsed, setCollapsed }}>
      <div {...props} className={className}>
        {props.children}
      </div>
    </CardContext.Provider>
  )
}
