import { Button, Col, Panel, PanelContent, PanelHeader, Row, Text } from 'inspinia-template'
import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import JSONPretty from 'react-json-pretty'
import { useNavigate, useParams } from 'react-router-dom'
import { httpPost } from 'common/services'
import { backendURL } from '../../services/api'

interface MonitorarCobranca {
  orcamento: string
  uuid: string
}

export function MonitorarCobranca() {
  const navigate = useNavigate()

  const { id } = useParams()

  const [orcamento, setOrcamento] = useState('')
  const [uuid, setUuid] = useState('')
  const [showResult, setShowResult] = useState(false)

  const queryClient = useQueryClient()

  const { mutate, data } = useMutation({
    mutationFn: (cobranca: MonitorarCobranca) =>
      httpPost<unknown>(`${backendURL}/blu/monitor/`, cobranca)
        .then((res) => {
          if (!res.ok) {
            setShowResult(false)
            throw new Error('Failed to fetch')
          }

          return res.json()
        })
        .catch((reason) => {
          console.log(reason)
          if (reason === 401) {
            navigate('/login')
          }
        }),

    onSuccess: (res) => {
      console.log(res)
    },
    onError: (err) => {
      console.log(err)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['monitor'] })
    },
  })

  const enviar = async () => {
    await mutate({ orcamento: orcamento, uuid: uuid })
    setShowResult(true)
  }
  return (
    <>
      <div className="wrapper wrapper-content  animated fadeInRight">
        <Row>
          <Col sm={6}>
            <Panel>
              <PanelHeader>
                <h5>Monitorar cobrança</h5>
              </PanelHeader>
              <PanelContent>
                <div className="form-group row">
                  <Text label="Orçamento" name="orcamento" onChange={(event) => setOrcamento(event.target.value)} />
                </div>
                <div className="form-group row">
                  <Text
                    label="UUID"
                    name="cpfcnpj"
                    onChange={(event) => setUuid(event.target.value)}
                    defaultValue={id}
                  />
                </div>

                <div className="form-group row">
                  <div className="col-sm-10 offset-sm-2">
                    <Button label="Enviar" variant="primary" onClick={enviar}></Button>
                  </div>
                </div>
                {showResult && (
                  <div className="">
                    <JSONPretty id="json-pretty" data={data}></JSONPretty>
                  </div>
                )}
              </PanelContent>
            </Panel>
          </Col>
        </Row>
      </div>
    </>
  )
}
