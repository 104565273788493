import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

export function ErrorBoundary() {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <div className="middle-box text-center animated fadeInDown">
        <h1>{error.status}</h1>
        <h3 className="font-bold">{error.statusText}</h3>

        <div className="error-desc">
          {error.status === 404 && (
            <p>
              Sorry, but the page you are looking for has note been found. Try checking the URL for error, then hit the
              refresh button on your browser or try found something else in our app.
            </p>
          )}
        </div>
      </div>
    )
  }
}
