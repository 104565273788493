export const attributesAnymarketBrand = [
  { name: 'name', title: 'Nome', class: '', type: 'string', required: true },
  { name: 'reducedName', title: 'Nome reduzido', class: '', type: 'string', required: false },
  { name: 'partnerId', title: 'Código no parceiro', class: '', type: 'string', required: false },
]

export const attributesAnymarketCategory = [{ name: 'name', title: 'Nome', class: '', type: 'string', required: true }]

export const attributesAnymarketVariation = [
  { name: 'name', title: 'Nome', class: '', type: 'string', required: true },
  { name: 'visualVariation', title: 'Variação visual', class: '', type: 'boolean', required: true },
]

export const attributesMultiversoProductList = [
  { name: 'id', title: 'SKU', class: '', type: 'string', required: true },
  { name: 'sku', title: 'SKU Global', class: '', type: 'string', required: true },
  { name: 'titulo', title: 'Título', class: '', type: 'string', required: true },
  { name: 'modelo', title: 'Modelo', class: '', type: 'string', required: true },
  { name: 'estoque', title: 'Estoque', class: '', type: 'string', required: true },
  { name: 'fornecedor', title: 'Cód Fab', class: '', type: 'string', required: true },
  { name: 'nomeFornecedor', title: 'Fabricante', class: '', type: 'string', required: true },
]

export const attributesMultiversoProduct = [
  { name: 'id', title: 'SKU', class: '', type: 'string', required: true },
  { name: 'sku', title: 'SKU Global', class: '', type: 'string', required: true },
  { name: 'titulo', title: 'Título', class: '', type: 'string', required: true },
  { name: 'modelo', title: 'Modelo', class: '', type: 'string', required: true },
  { name: 'marca', title: 'Marca', class: '', type: 'string', required: true },
  { name: 'categorias', title: 'Categoria', class: '', type: 'string', required: true },
  { name: 'descricaoCurta', title: 'Descrição', class: '', type: 'text', required: true },
  { name: 'especificacaoTecnica', title: 'Informações', class: '', type: 'text', required: true },
  { name: 'fornecedor', title: 'Cód Fab', class: '', type: 'string', required: true },
  { name: 'nomeFornecedor', title: 'Fabricante', class: '', type: 'string', required: true },
  { name: 'pesoLiquido', title: 'Peso líquido (kg)', class: '', type: 'string', required: true },
  { name: 'pesoBruto', title: 'Peso líquido (kg)', class: '', type: 'string', required: true },
  { name: 'warrantyTime', title: 'Tempo de garantia', class: '', type: 'string', required: true },
  { name: 'warrantyText', title: 'Texto da garantia', class: '', type: 'string', required: true },
  { name: 'height', title: 'Altura (cm)', class: '', type: 'string', required: true },
  { name: 'width', title: 'Largura (cm)', class: '', type: 'string', required: true },
  { name: 'length', title: 'Profundidade (cm)', class: '', type: 'string', required: true },
  { name: 'weight', title: 'Peso (kg)', class: '', type: 'string', required: true },
  { name: 'nbm.id', title: 'NCM', class: '', type: 'string', required: true },
  { name: 'origin.id', title: 'Origem', class: '', type: 'string', required: true },
  { name: 'skus.ean', title: 'Código de barras', class: '', type: 'string', required: true },
  { name: 'estoque', title: 'Estoque', class: '', type: 'string', required: true },
  { name: 'precoCusto', title: 'Preço (R$)', class: '', type: 'string', required: true },
  { name: 'preco', title: 'Preço de venda (R$)', class: '', type: 'string', required: true },
]
