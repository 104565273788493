/* eslint-disable  @typescript-eslint/no-explicit-any */
import { FormEvent, useCallback, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { Button, Col, Card, CardContent, CardHeader, Row, Text, TextArea } from 'inspinia-template'

import 'react-toastify/dist/ReactToastify.css'
import 'sweetalert2/src/sweetalert2.scss'
import { FaTimes } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { useRead } from '../services/api'

export type AttributeProps = {
  class: string
  name: string
  title: string
  type: string
  readOnly?: boolean
  required?: boolean
}

export type TelaEditarProps = {
  attributes: AttributeProps[]
  breadcrumbs: string[]
  baseUrl: string
  model?: string
  title: string
}

export function TelaEditar<T>(props: TelaEditarProps) {
  const navigate = useNavigate()

  const params = useParams<{ id: string }>()

  const { data, isFetched, isFetching, refetch } = useRead<T>(props.baseUrl, params.id || '')

  if (!isFetched && !isFetching) {
    refetch()
  }

  //  const [data, setData] = useState<T>({} as T)

  const formRef = useRef<HTMLFormElement>(null)

  // useEffect(() => {
  //   console.log('effect')
  //   if (parseInt(params.id) > 0) {
  //     useRead<{ [key: string]: string | number }>(props.model, params.id).then((data) => {
  //       setData(data)
  //     })
  //   } else {
  //     setData({})
  //   }
  // }, [params.id, props.model])

  const [deleted, setDeleted] = useState(false)

  const deletar = async (event: FormEvent) => {
    event.preventDefault()

    Swal.fire('Deseja excluir?', 'Você tem certeza que deseja excluir este registro?', 'warning').then(
      async (value) => {
        if (value.isConfirmed) {
          TrevoService.remove(props.model, params.id).then(
            () => {
              setDeleted(true)
              toast.info('Registro excluído.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                icon: true,
                theme: 'colored',
              })
            },
            () => {
              toast.error('Não foi possível excluir.', {
                position: 'top-right',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                icon: true,
                theme: 'colored',
              })
            },
          )
        }
      },
    )
    Swal.update({
      title: 'Deseja excluir?',
      text: 'Você tem certeza que deseja excluir este registro?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Sim, pode excluir!',
    })
  }

  const salvar = async (event: FormEvent) => {
    event.preventDefault()

    formRef.current?.classList.add('was-validated')

    if (!formRef.current || !formRef.current?.checkValidity()) {
      return
    }

    const putData = {} as any
    putData[props.model] = {} as any

    putData[props.model]['id'] = (formRef.current.elements.namedItem('id') as HTMLInputElement).value
    putData[props.model]['revision'] = (formRef.current.elements.namedItem('revision') as HTMLInputElement).value
    putData[props.model] = { ...putData[props.model], ...data }

    try {
      const ret = await TrevoService.update(props.model, params.id, putData)
      setData(ret)

      toast.success('Alterações salvas', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: true,
        theme: 'colored',
        onClose: () => {
          formRef.current?.classList.remove('was-validated')
        },
      })

      navigate('../' + props.model.toLowerCase() + '/' + ret.id)

      return true
    } catch (exception) {
      toast.error('Não foi possível salvar as alterações.', {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: true,
        theme: 'colored',
        onOpen: () => {
          formRef.current?.classList.remove('was-validated')
        },
      })
    }
  }

  const GetValue = useCallback(
    (attributeName: string) => {
      if (attributeName.indexOf('.') >= 0) {
        const attlist = attributeName.split('.')
        if (undefined !== data[attlist[0]]) {
          if (undefined !== data[attlist[0]][attlist[1]]) {
            return data[attlist[0]][attlist[1]]
          } else if (data[attlist[0]][0]) {
            return data[attlist[0]][0][attlist[1]]
          }
        }
      }

      return data[attributeName]
    },
    [data],
  )

  return (
    <div className="content-wrapper">
      <div className="content-heading">
        <div>
          {data && data.id > 0 && <div>Editar {props.title}</div>}
          {data && !(data.id > 0) && <div>Cadastrar {props.title}</div>}
        </div>
        <div className="ml-auto">
          <div className="btn-group">
            {/* <Button icon="plus" label="Cadastrar novo" primary large onClick={() => navigate(`${props.baseUrl}/cadastrar`)} /> */}
          </div>
        </div>
      </div>
      {isFetched && (
        <Row>
          <Col all={12}>
            <Card className="col-lg-6">
              <CardHeader>
                <h5>Informações</h5>
              </CardHeader>
              <CardContent>
                {deleted && (
                  <div className="deletedOverlay">
                    <div className="text">
                      <FaTimes /> Registro excluído.
                    </div>
                  </div>
                )}
                <form ref={formRef}>
                  <input type="hidden" name="id" defaultValue={data['id']} />
                  <input type="hidden" name="revision" defaultValue={data['revision']} />
                  {props.attributes.map((attribute, index) => (
                    <div key={index}>
                      <div className="form-group row">
                        {attribute.type === 'readonly' && (
                          <Text
                            defaultValue={data[attribute.name]}
                            label={attribute.title}
                            name={attribute.name}
                            readOnly={true}
                          />
                        )}
                        {attribute.type === 'string' && (
                          <Text
                            defaultValue={GetValue(attribute.name)}
                            label={attribute.title}
                            name={attribute.name}
                            required={attribute.required}
                            onChange={(event) => (data[attribute.name] = event.target.value)}
                          />
                        )}
                        {attribute.type === 'text' && (
                          <TextArea
                            defaultValue={GetValue(attribute.name)}
                            label={attribute.title}
                            name={attribute.name}
                            onChange={(event) => (data[attribute.name] = event.target.value)}
                            required
                          />
                        )}
                      </div>
                      <div className="hr-line-dashed"></div>
                    </div>
                  ))}

                  <div className="form-group row">
                    <div className="col-sm-10 offset-sm-2">
                      <Button
                        label="Voltar"
                        variant="secondary"
                        className="mr-2"
                        onClick={(e) => {
                          e.preventDefault()
                          navigate(-1)
                        }}
                      />
                      <Button
                        label={data.id > 0 ? 'Salvar alterações' : 'Cadastrar'}
                        variant="primary"
                        className="m-l-sm"
                        onClick={salvar}
                      />
                      {data.id > 0 && (
                        <Button
                          label="Excluir"
                          variant="secondary"
                          className="float-right"
                          disabled={!data.id}
                          onClick={deletar}
                        />
                      )}
                    </div>
                  </div>
                </form>
              </CardContent>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  )
}
