import { useCallback } from 'react'
import { TabHeaderProps } from 'common'

export function TabHeader(props: TabHeaderProps) {
  const {
    active = false,
    badge,
    badgeClass = 'badge',
    badgePosition = 'end',
    icon,
    iconPosition = 'start',
    index,
    label,
    setActiveTab,
    ...liProps
  } = props

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      if (setActiveTab) setActiveTab(index ?? 0)
    },
    [setActiveTab, index],
  )

  return (
    <li {...liProps}>
      <a
        href={`#tab-${index}`}
        className={`nav-link ${active ? 'active' : ''}`}
        data-toggle="tab"
        onClick={handleClick}
      >
        {badgePosition === 'start' && <span className={`${badgeClass} m-r-xs`}>{badge}</span>}
        {iconPosition === 'start' && icon}
        {label}
        {props.children}
        {iconPosition === 'end' && icon}
        {badgePosition === 'end' && <span className={`${badgeClass} m-l-xs`}>{badge}</span>}
      </a>
    </li>
  )
}
