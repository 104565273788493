import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './hooks/useAuth'

export type ProtectedRouteProps = {
  children?: ReactNode
  element?: ReactNode
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { user, hasMFA, isMFAVerified } = useAuth()

  const location = useLocation()

  sessionStorage.setItem('location', location.pathname)

  if (!user) {
    return <Navigate to="/login" replace={true} />
  }
  if (hasMFA && !isMFAVerified) {
    return <Navigate to="/verify-mfa" replace={true} />
  }

  if (props.element) {
    return props.element
  }

  return props.children
}
