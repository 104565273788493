import { ReactNode, createContext, useContext, useEffect, useId, useState } from 'react'
import { MenuContext } from './SidebarNavigation.tsx'

interface MenuItemContextType {
  isCollapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

export const MenuItemContext = createContext<MenuItemContextType | undefined>(undefined)

export type SidebarNavigationItemProps = {
  children?: ReactNode
  className?: string
  collapsed?: boolean
  collapsible?: boolean
  post?: ReactNode
  pre?: ReactNode
  isCollapsed?: boolean
  label?: string
  setCollapsed?: React.Dispatch<React.SetStateAction<boolean>>
  target?: string
}

export function SidebarNavigationItem(props: SidebarNavigationItemProps) {
  const context = useContext(MenuContext)
  const id = useId()
  const [isCollapsed, setCollapsed] = useState<boolean>(props.collapsed ?? true)

  useEffect(() => {
    if (id !== context?.activeItem) {
      setCollapsed(true)
    }
  }, [id, context?.activeItem])

  function changeCollapse(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    event.preventDefault()

    context?.setActiveItem(isCollapsed ? id : '')
    setCollapsed(!isCollapsed)
  }

  if (props.collapsible) {
    return (
      <MenuItemContext.Provider value={{ isCollapsed, setCollapsed }}>
        <li
          className={''.concat(props.className ?? '', isCollapsed === false ? ' active' : '')}
          onClick={(e) => changeCollapse(e)}
        >
          {props.label && (
            <a href={props.target ?? '#'}>
              {props.pre} <span className="nav-label">{props.label}</span> {props.post}
            </a>
          )}
          {props.children}
        </li>
      </MenuItemContext.Provider>
    )
  } else if (props.target && props.label) {
    return (
      <li className={props.className}>
        <a href={props.target}>
          {props.pre} <span className="nav-label">{props.label}</span> {props.post}
        </a>
      </li>
    )
  } else {
    return <li className={props.className}>{props.children}</li>
  }
}
