import { useContext } from 'react'
import classNames from 'classnames'
import { PanelContentProps, PanelContext } from 'common'

export function PanelContent(props: PanelContentProps) {
  const className = classNames('ibox-content', props.className)
  const context = useContext(PanelContext)

  return (
    <>
      {context?.collapsible && (
        <div
          {...props}
          id="collapseOne"
          className={`panel-collapse ${context.isCollapsed ? 'collapse' : 'collapse show'}`}
        >
          <div className={className}>{props.children}</div>
        </div>
      )}
      {!context?.collapsible && (
        <div {...props} className={className}>
          {props.children}
        </div>
      )}
    </>
  )
}
