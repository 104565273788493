import { FormEvent, useState } from 'react'
import { FaChevronDown, FaChevronLeft, FaList, FaStore, FaTable, FaUpload } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import {
  Button,
  DropDownMenu,
  MenuDivider,
  MenuItem,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SidebarNavigation,
  SidebarNavigationGroup,
  SidebarNavigationItem,
} from 'inspinia-template'
import { httpPost } from 'common/services'
import { useAuth } from '../hooks/useAuth'
import { backendURL } from '../services/api'

export function Sidebar() {
  const { user, logout } = useAuth()
  const navigate = useNavigate()

  const [showDropDown, setShowDropDown] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const collapsedIcon = <FaChevronLeft size="14" style={{ float: 'right', paddingTop: '5px' }} />
  const expandedIcon = <FaChevronDown size="14" style={{ float: 'right', paddingTop: '5px' }} />

  const handleLogout = (event: FormEvent) => {
    event.preventDefault()

    logout()
    navigate('/login')
  }

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [error, setError] = useState(false)

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (newPassword !== '' && newPassword2 !== '' && newPassword !== newPassword2) {
      setError(true)
    }

    httpPost(`${backendURL}/changepassword`, {
      Email: user?.Email,
      OldPassword: oldPassword,
      NewPassword: newPassword,
    }).then((result) => {
      if (result.ok) {
        setOldPassword('')
        setNewPassword('')
        setNewPassword2('')
        setShowModal(false)
      }
    })
  }

  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <SidebarNavigation>
          <li className="nav-header">
            <div className="dropdown profile-element">
              <img alt="" className="rounded-circle" src="/profile_small.jpg" />
              <a
                data-toggle="dropdown"
                className="dropdown-toggle"
                href="#"
                onClick={(event) => {
                  event.preventDefault()
                  setShowDropDown(!showDropDown)
                }}
                onBlur={() => setTimeout(() => setShowDropDown(false), 100)}
              >
                {/* <span className="block m-t-xs font-bold">{user?.Email}</span> */}
                <span className="text-muted text-xs block">
                  {user?.Email} <b className="caret"></b>
                </span>
              </a>
              <DropDownMenu className="animated fadeInRight m-t-xs" show={showDropDown}>
                <MenuItem
                  label="Alterar senha"
                  onClick={() => {
                    setShowModal(true)
                  }}
                />
                <MenuDivider />
                <MenuItem label="Sair" target="/logout" onClick={handleLogout} />
              </DropDownMenu>
            </div>
          </li>
          <div className="logo-element">IN+</div>

          <SidebarNavigationGroup collapsedIcon={collapsedIcon} expandedIcon={expandedIcon} label="Anymarket">
            <SidebarNavigationItem>
              <Link to="/anymarket/categorias">
                <i className="fa fa-diamond"></i> <span className="nav-label">Categorias</span>
              </Link>
            </SidebarNavigationItem>
            <SidebarNavigationItem>
              <Link to="/anymarket/brands">
                <i className="fa fa-diamond"></i> <span className="nav-label">Marcas</span>
              </Link>
            </SidebarNavigationItem>
            <SidebarNavigationItem>
              <Link to="/anymarket/pedidos">
                <i className="fa fa-diamond"></i> <span className="nav-label">Pedidos</span>
              </Link>
            </SidebarNavigationItem>
            <SidebarNavigationItem>
              <Link to="/anymarket/produtos">
                <i className="fa fa-diamond"></i> <span className="nav-label">Produtos</span>
              </Link>
            </SidebarNavigationItem>
            <SidebarNavigationItem>
              <Link to="/anymarket/transmissoes">
                <i className="fa fa-diamond"></i> <span className="nav-label">Transmissões</span>
              </Link>
            </SidebarNavigationItem>
            <SidebarNavigationItem>
              <Link to="/anymarket/variacoes">
                <i className="fa fa-diamond"></i> <span className="nav-label">Variações</span>
              </Link>
            </SidebarNavigationItem>
          </SidebarNavigationGroup>
          <SidebarNavigationGroup collapsedIcon={collapsedIcon} expandedIcon={expandedIcon} label="Blu">
            {/* <li className="nav-header">
                <div className="dropdown profile-element">
                    <img alt="image" className="rounded-circle" src="img/profile_small.jpg"/>
                    <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                        <span className="block m-t-xs font-bold">David Williams</span>
                        <span className="text-muted text-xs block">Art Director <b className="caret"></b></span>
                    </a>
                    <ul className="dropdown-menu animated fadeInRight m-t-xs">
                        <li><a className="dropdown-item" href="profile.html">Profile</a></li>
                        <li><a className="dropdown-item" href="contacts.html">Contacts</a></li>
                        <li><a className="dropdown-item" href="mailbox.html">Mailbox</a></li>
                        <li className="dropdown-divider"></li>
                        <li><a className="dropdown-item" href="login.html">Logout</a></li>
                    </ul>
                </div>
                <div className="logo-element">
                    IN+
                </div>
            </li> */}
            <SidebarNavigationItem>
              <Link to="/blu/monitor">
                <i className="fa fa-diamond"></i> <span className="nav-label">Monitorar</span>
              </Link>
            </SidebarNavigationItem>
            <SidebarNavigationItem>
              <Link to="/blu/consultar-cliente">
                <i className="fa fa-diamond"></i> <span className="nav-label">Consultar cliente</span>
              </Link>
            </SidebarNavigationItem>
            {user?.Grupo == 1 && (
              <>
                <SidebarNavigationItem>
                  <Link to="/blu/consultar-cobranca">
                    <i className="fa fa-diamond"></i> <span className="nav-label">Consultar cobrança</span>
                  </Link>
                </SidebarNavigationItem>
                <SidebarNavigationItem>
                  <Link to="/blu/enviar-cobranca">
                    <i className="fa fa-diamond"></i> <span className="nav-label">Enviar cobrança</span>
                  </Link>
                </SidebarNavigationItem>
                <SidebarNavigationItem>
                  <Link to="/blu/monitorar-cobranca">
                    <i className="fa fa-diamond"></i> <span className="nav-label">Monitorar cobrança</span>
                  </Link>
                </SidebarNavigationItem>
              </>
            )}
          </SidebarNavigationGroup>
          <SidebarNavigationGroup
            collapsedIcon={collapsedIcon}
            expandedIcon={expandedIcon}
            label="Tray"
          ></SidebarNavigationGroup>
          <SidebarNavigationGroup collapsedIcon={collapsedIcon} expandedIcon={expandedIcon} label="SZ.chat">
            <SidebarNavigationItem>
              <Link to="/szchat/statistics">
                <span className="nav-label">Estatísticas</span>
              </Link>
            </SidebarNavigationItem>
            <SidebarNavigationItem>
              <Link to="/szchat/products-soldout">
                <span className="nav-label">Produtos sem estoque</span>
              </Link>
            </SidebarNavigationItem>
          </SidebarNavigationGroup>
          <SidebarNavigationGroup collapsedIcon={collapsedIcon} expandedIcon={expandedIcon} label="Zuma">
            <SidebarNavigationItem>
              <Link to="/zuma/products">
                <FaStore /> <span className="nav-label">Consultar Produtos</span>
              </Link>
            </SidebarNavigationItem>
            <SidebarNavigationItem>
              <Link to="/zuma/editar-produtos">
                <FaTable /> <span className="nav-label">Editar Produtos</span>
              </Link>
            </SidebarNavigationItem>
            <SidebarNavigationItem>
              <Link to="/zuma/upload-imagens">
                <FaUpload /> <span className="nav-label">Upload de imagens</span>
              </Link>
            </SidebarNavigationItem>
            {/* <SidebarNavigationItem>
              <Link to="/zuma/caracteristicas/61811">
                <FaList /> <span className="nav-label">Características</span>
              </Link>
            </SidebarNavigationItem> */}
          </SidebarNavigationGroup>
        </SidebarNavigation>

        <Modal isOpen={showModal} setOpen={setShowModal}>
          <ModalHeader title="Alterar senha" setOpen={setShowModal} />
          <ModalContent>
            <form className="m-t" role="form" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Senha Atual"
                  required={true}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Nova Senha"
                  required={true}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirmar Senha Nova"
                  required={true}
                  value={newPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                />
              </div>
              <Button type="submit" variant="primary" className="block m-b">
                Alterar
              </Button>
            </form>
            {error && <div>Usuário ou senha incorretos</div>}
          </ModalContent>
          <ModalFooter />
        </Modal>
      </div>
    </nav>
  )
}
