import classNames from 'classnames'
import { DropDownMenuProps } from 'common'
import { MenuDivider, MenuItem } from '../main.ts'

export function DropDownMenu(props: DropDownMenuProps) {
  const { show, ...ulProps } = props

  const className = classNames('dropdown-menu', show === true ? 'show' : '', props.className)

  return (
    <ul {...ulProps} className={className} role="menu">
      {props.children?.map((item) => {
        if (item.type === MenuDivider) {
          return item
        } else if (item.type === MenuItem) {
          return item
        }

        return <></>
      })}
    </ul>
  )
}
