/* eslint-disable @typescript-eslint/no-explicit-any */

import Tippy from '@tippyjs/react'
import { httpGet, HttpResponse } from 'common/services'
import { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

export const PopupCellRenderer = (props: any) => {
  const tippyRef = useRef()
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  const catId = props.data.categorias?.split(';')[0]

  const dropDownContent = (
    <div className="menu-container">
      {/* <div onClick={() => onClickHandler('compare')} className="menu-item">
        Comparar
      </div>
      <div onClick={() => onClickHandler('copy')} className="menu-item">
        Copiar
      </div> */}
      {/* <div onClick={() => onClickHandler('edit-characteristics')} className="menu-item"> */}
      <a
        href={`/zuma/caracteristicas/${props.data.id}/${catId}`}
        target="_blank"
        className="menu-item"
        onClick={(e) => {
          console.log(catId)
          if (!(catId > 0)) {
            alert('Favor selecionar a Categoria Anymarket primeiro!')
            e.preventDefault()
          }
          hide()
        }}
      >
        Editar características
      </a>
      {/* </div> */}
      <div onClick={() => onClickHandler('edit-images')} className="menu-item">
        Editar fotos
      </div>
      <a
        href={`/backend/redirect/anymarket/${props.data.id}`}
        target="_blank"
        className="menu-item"
        onClick={() => {
          hide()
        }}
      >
        Exibir na Anymarket
      </a>
      <a
        href={`/backend/redirect/tray/${props.data.id}`}
        target="_blank"
        className="menu-item"
        onClick={() => {
          hide()
        }}
      >
        Exibir na Tray
      </a>
      <div onClick={() => onClickHandler('validate-anymarket')} className="menu-item">
        Validar Anymarket
      </div>
      <div onClick={() => onClickHandler('validate-tray')} className="menu-item">
        Validar Tray
      </div>
      <div onClick={() => onClickHandler('export-anymarket')} className="menu-item">
        Exportar para Anymarket
      </div>
      <div onClick={() => onClickHandler('export-tray')} className="menu-item">
        Exportar para Tray
      </div>
    </div>
  )

  const onClickHandler = (option: any) => {
    hide()
    if (option === 'copy') {
      console.log(props)
      const result = []

      for (const value of Object.values(props.data)) {
        result.push(value)
      }

      navigator.clipboard.writeText(result.join('\t')).then(
        function () {
          console.log('Copying to clipboard was successful!')
        },
        function (err) {
          console.error('Could not copy text: ', err)
          // console.log('trying fallback')
          // fallbackCopyTextToClipboard(text)
        },
      )
    }
    if (option === 'create') {
      props.api.applyTransaction({
        add: [{}],
      })
    }
    if (option === 'delete') {
      props.api.applyTransaction({ remove: [props.data] })
    }

    if (option === 'edit-images') {
      console.log(props)
      if (props.setId) props.setId(props.data.id)
      props.setShowModalFotos(true)
      // props.api.startEditingCell({
      //   rowIndex: props.rowIndex,
      //   colKey: 'make',
      // })
    }

    if (option === 'export-anymarket') {
      exportTo(props.data.id, 'anymarket')
    }

    if (option === 'export-tray') {
      exportTo(props.data.id, 'tray')
    }

    if (option === 'validate-anymarket') {
      validate(props.data.id, 'anymarket')
    }

    if (option === 'validate-tray') {
      validate(props.data.id, 'tray')
    }
  }
  interface RespostaValidacao {
    campo: string
    mensagem: string
    tipo: number
  }

  interface ListResponse<T> extends HttpResponse<T[]> {}

  const validate = async (sku: number, target: string) => {
    // window.open(
    const response: ListResponse<RespostaValidacao> = (await httpGet<RespostaValidacao>(
      `/backend/zuma/validate/${sku}/${target}`,
    )) as ListResponse<RespostaValidacao>
    const respostas = response.value

    let result = 0
    let mensagens = ''
    respostas?.forEach((element) => {
      if (element.tipo === 1) {
        result = 1
        mensagens = mensagens + ', ' + element.mensagem
      }
      if (element.tipo === 2) {
        result = 2
        mensagens = mensagens + ', ' + element.mensagem
      }
    })

    if (result === 0) {
      Swal.fire('Validar', 'Informações válidas para exportação para ' + target, 'success')
    } else if (result === 1) {
      Swal.fire(
        'Validar',
        'Informações válidas para exportação para ' + target + ' mas há alertas: ' + mensagens.substring(2),
        'warning',
      )
    } else {
      Swal.fire(
        'Validar',
        'Informações inválidas para exportação para ' + target + ': ' + mensagens.substring(2),
        'error',
      )
    }
  }
  const exportTo = async (sku: number, target: string) => {
    // window.open(
    const response: ListResponse<string> = (await httpGet<string>(
      `/backend/zuma/export/${sku}/${target}`,
    )) as ListResponse<string>
    const mensagem = response.value

    if (undefined === mensagem) {
      toast.success('SKU ' + sku + ' exportado com sucesso para ' + target, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: true,
        theme: 'colored',
        onClose: () => {},
      })
    } else {
      toast.error('SKU ' + sku + ' inválido para exportação para ' + target, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: true,
        theme: 'colored',
        onClose: () => {},
      })
    }
  }

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <button className="btn btn-xs btn-primary" onClick={visible ? hide : show}>
        ...
      </button>
    </Tippy>
  )
}

export default PopupCellRenderer
