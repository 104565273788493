import { PropsWithChildren, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'common/hooks'
import { httpPost } from 'common/services'
import { AuthContext, User } from './AuthContext'
import { backendURL } from '../services/api'

export type Error = {
  error: string
}

export type LoginRequest = {
  email: string
  password: string
}

export type LoginResponse = {
  user: User
}

export const defaultLocation = '/'

export const AuthProvider = (props: PropsWithChildren) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _, removeCookie] = useCookies()
  const [user, setUser] = useLocalStorage('user', null)
  const [isMFAVerified, setIsMFAVerified] = useState(false)
  const navigate = useNavigate()

  const hasMFA = false

  const navigateBack = (options?: object) => {
    navigate(sessionStorage.getItem('location') ?? defaultLocation, options)
  }

  const login = async (name: string, password: string) => {
    const response = await httpPost<LoginResponse | Error>(`${backendURL}/login`, { email: name, password })

    if (response.status === 200) {
      if ((response.value as LoginResponse).user.ID > 0) {
        const user = (response.value as LoginResponse).user
        // user.avatar = backendURL + '/avatar.php?uid=' + user.ID

        setUser(user)

        if (hasMFA) {
          navigate('/verify-mfa')
        } else {
          navigateBack()
        }

        return true
      }
    }

    return false
  }

  const logout = async () => {
    setUser(undefined)

    setIsMFAVerified(false)
    ;['token', 'name'].forEach((obj) => removeCookie(obj)) // remove data save in cookies

    navigateBack({ replace: true })
  }

  const verifyMFACode = async (code: string) => {
    // Mock verification logic
    if (code === '0000') {
      setIsMFAVerified(true)

      navigateBack()

      return true
    }
    return false
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        hasMFA,
        isMFAVerified,
        cookies,
        login,
        logout,
        verifyMFACode,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
